<script setup>
import { computed, onBeforeMount, onMounted, ref } from "vue";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import admins from "../../services/admins";

const route = useRoute();
const store = useStore();
const printing = ref(false);
const information = ref(null);
const productos = ref([]);

onBeforeMount(() => {
  if (!store.getters?.getUser?.id) location.href = "/login";
})

onMounted(async () => {
  const { id } = route.query;
  let search = {
    multiple: {
      queries: [
        {
          param: "id",
          value: id,
        },
      ]
    }
  }


  let res = await store.dispatch("get", {
    tableName: "ordenes",
    search
  });

  if (!res?.data?.data?.[0]) return;
  information.value = res?.data?.data?.[0];

  if (information.value?.status?.toLowerCase() !== 'confirmado' && !admins.includes(store.getters?.getUser?.email)) location.href = "/compras";

  let prod = await store.dispatch("get", {
    tableName: "producto_ordenes",
    search: {
      strict: true,
      param: "orden_id",
      value: id
    }
  })

  productos.value = prod?.data?.data;

  for (const producto of productos.value) {
    const response = await store.dispatch('get', {
      tableName: "products",
      search: {
        strict: true,
        param: 'codigo',
        value: producto?.producto_codigo
      }
    })
    const _producto = (response.data.data?.[0])
    if (_producto && Number(_producto?.producto_especial)) {
      producto.precio_final = Number(_producto?.precio) - ((Number(_producto?.producto_especial) * Number(_producto?.precio)) / 100);

    }
  }
})

const printTicket = async () => {
  printing.value = true;
  setTimeout(async () => {
    const element = document.getElementById("ticket");
    const canvas = await html2canvas(element);
    const pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    let widthRatio = pageWidth / canvas.width;
    let heightRatio = pageHeight / canvas.height;
    let ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
    let imgWidth = canvas.width * ratio;
    let imgHeight = canvas.height * ratio;
    let pageData = canvas.toDataURL('image/jpeg', 1.0);
    let totalPages = Math.ceil(imgHeight / pageHeight);

    for (let i = 0; i < totalPages; i++) {
      pdf.addImage(pageData, 'JPEG', 0, -i * pageHeight, imgWidth, imgHeight);
      if (i < (totalPages - 1)) {
        pdf.addPage();
      }
    }

    pdf.save('download.pdf');
    printing.value = false;
  }, 1000);
}

const total = computed(() => {
  let price = 0;
  if (productos.value) {
    productos.value.forEach((x) => {
      price += parseInt(x?.precio_final ?? x?.precio) * parseInt(x?.cantidad);
    })
  }
  return price?.toFixed(2);
})

const envios = {
  "envio_domicilio": "Envio a Domicilio (Solo Florencio Varela)",
  "envio_correo": "Envio a Domicilio (Correo)",
  "retiro_local": "Retiro en local",
};
</script>


<template>
  <div id="ticket">
    <div class="ticket">
      <h2 class="ticket-title">Ticket de Compra</h2>

      <!-- Datos del usuario -->
      <div class="ticket-section">
        <h3 class="section-title">Datos del Usuario</h3>
        <p class="user-info">Nombre: {{ information?.nombre }}</p>
        <p class="user-info">Email: {{ information?.email }}</p>
        <p class="user-info">Dirección: {{ information?.direccion }}</p>
        <p class="user-info">Ciudad: {{ information?.ciudad }}</p>
        <p class="user-info">Codigo postal: {{ information?.codigoPostal }}</p>
        <p class="user-info">Forma de pago: {{ information?.pago }}</p>
        <p class="user-info">Entrega:
          {{ envios[information?.envio] }}
        </p>
      </div>

      <!-- Productos comprados -->
      <div class="ticket-section">
        <h3 class="section-title">Productos Comprados</h3>
        <ul class="product-list">
          <li class="product-item" v-for="(producto, key) in productos" :key=key>
            <span class="product-name">{{ producto?.nombre }}</span><br>
            <span class="product-price">Precio: AR$ {{ producto?.precio_final ??  producto?.precio }} - </span>
            <span class="product-quantity">Cantidad: {{ producto?.cantidad }}</span>
            <br>
            <b>Observaciones: </b> <br>
            <span class="section-title" v-if="producto?.observacion">{{ producto?.observacion }}</span><br>
          </li>
        </ul>
      </div>

      <!-- Costo de envío -->


      <!-- Total -->
      <div class="ticket-section">
        <h3 class="section-title">Total</h3>
        <p class="total-amount">AR$ {{ total }}</p>
      </div>

      <a v-if="!printing" @click="printTicket">Imprimir</a>
    </div>
  </div>
</template>


<style scoped>
a {
  color: dodgerblue;
  cursor: pointer;
}

.volver {
  margin-right: 1em;
}

#ticket {
  margin: auto;
}

.ticket {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 600px;
  margin-top: 210px !important;
  margin: 50px auto;
}

.ticket-title {
  text-align: center;
  color: #333;

  margin-bottom: .5em;
}

.ticket-section {
  border-top: 1px solid #ccc;
  padding-top: 1em;
  margin-bottom: 20px;

}

.section-title {
  font-size: 18px;
  color: #555;
}

.user-info {
  margin-bottom: 8px;
  font-size: 16px;
  color: #777;
}

.product-list {
  list-style: none;
  padding: 0;
}

.product-item {
  margin-top: 1em;
}

.product-name {
  font-weight: bold;
}

.product-price,
.product-quantity {}

.shipping-cost,
.total-amount {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.total-amount {
  color: #08A208;
}
</style>