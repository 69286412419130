import { defineStore } from 'pinia'
import {post} from "./apiReq";
export const useCarritoStore = defineStore('carrito', {
    state: () => ({
        cantidad: 0,
        productos: [],
    }),
    actions: {
        async get () {
            this.cantidad = 0;
            let res = await post("/carrito/get");
            let productos = res.data?.productos;

            productos?.forEach((producto) => {
                this.cantidad += Number(producto?.cantidad);
            })

            this.productos = productos;
        }
    }
})