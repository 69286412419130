<script setup>
import {backendUrl} from "../../../services/apiReq";
import {defaultImageProduct} from "@/utils/utils";
import {onMounted, reactive, ref} from "vue";
import {Modal, Input} from "digitalpower";
import {useStore} from "vuex";

const props = defineProps(["item", "addToCart"]);
const emits = defineEmits(["close"]);

const carouselInterno = reactive({});
const store = useStore();

const observacion = ref('');
const cartCounter = ref('');

onMounted(() => {
  console.log(props)
});
</script>

<template>
  <Modal
      class="modal-product"
      :title="item?.nombre"
      @close="
      () => {
        emits('close');
      }
    "
  >
    <div class="modal-product-container" flex flex-wrap>
      <div class="modal-product-image mb-5" flex flex-center>
        <div class="swiper-container2">
          <div class="swiper-wrapper">
            <div
                class="swiper-slide"
                v-for="(image, key) in item?.images ?? [
                `${item?.codigo_nombre}.png`,
              ]"
                :key="key"
            >
              <img
                  :src="`${backendUrl}/files/2/${image}`"
                  alt="La Fortaleza Producto"
                  @error="defaultImageProduct"
              />
              <div
                  class="no-stock-banner frio"
                  v-if="item?.frio"
                  shadow
                  slideRight
              >
                <p>Necesita cadena de frio {{ item?.frio }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-product-info">
        <h2 class="product__title">{{ item.nombre }}</h2>

        <div
            v-if="item?.value?.frio?.length > 0"
            radius-border
            class="orange"
            slide
        >
          <h4>Necesita cadena de frio</h4>
        </div>

        <div v-if="!item?.ofertas?.descuento && !item?.descuento">
          <h3 class="product__price">AR$ {{ item.precio }}</h3>
        </div>
        <div
            v-if="
            item?.ofertas?.descuento ||
            item?.descuento ||
            Number(item.producto_especial)
          "
        >
          <h3 class="discount product__price">
            <span class="discount-price">${{ item.precio }}</span> %{{
              item?.ofertas?.descuento ??
              item?.descuento ??
              item.producto_especial
            }}
            OFF
          </h3>
          <h3 class="product__price">
            ARS
            {{
              (
                  Number(item?.precio) -
                  (Number(item?.precio) *
                      Number(item?.ofertas?.descuento ?? item?.descuento ?? item?.producto_especial)) /
                  100
              )?.toLocaleString("es-AR")
            }}$
          </h3>
        </div>
        <br/>
        <div v-if="!store.getters.getUser?.id">
          <a href="/login" class="orangered"
          >Inicie sesión para ver más detalles</a
          >
        </div>

        <div flex flex-wrap gapped v-if="!item?.stock">
          <div class="quantity__control" flex flex-center>
            <div class="input__container">
              <Input
                  class="qty-input"
                  type="number"
                  v-model="cartCounter"
                  value="1"
                  min="1"
                  readonly
              />
            </div>
          </div>

          <Input
              type="button"
              value="Agregar"
              background="var(--primary)"
              color="white"
              @click="addToCart(item, observacion, cartCounter)"
          />
        </div>

        <Input
            class="mt-3"
            type="textarea"
            placeholder="Observaciones"
            id="observacion"
            v-if="!item?.stock"
            v-model="observacion"
        />
      </div>
    </div>
  </Modal>
</template>

<style scoped lang="scss">
.modal-product-container {
  justify-content: space-evenly;
}

.modal-product-image {
  align-items: start;
  width: 300px !important;
  overflow: hidden;
}

.modal-product-info {
  width: 400px;
}

.discount {
  color: gray !important;
  font-size: 0.9em !important;
}

.discount-price {
  text-decoration: line-through;
}

.swiper-container2 {
  width: 300px;
  height: 300px;

  .swiper-wrapper {
    width: 100%;
    height: 100%;

    .swiper-slide {
      width: 100%;
      height: 100%;

      img {
        width: 300px;
        height: 300px;
        max-height: 300px;
      }
    }
  }
}
</style>

<style lang="scss">
#observacion {
  height: 100px !important;
}

.modal-product {
  width: 90% !important;
  max-width: 1000px !important;
}
</style>
