<script setup>

</script>

<template>
  <div class="container">
    <div class="card">
      <h2 class="title">¿Querés ser parte de nuestro staff?</h2>
      <div class="requirements">
        <h3>Requisitos:</h3>
        <ul>
          <li>Mujer de 20 a 30 años.</li>
          <li>Tener experiencia en ventas de 2 años.</li>
          <li>Ser puntual, responsable y muy simpática.</li>
          <li>Saber vender y ser proactiva.</li>
          <li>Tener conocimiento en panadería, pizzería y repostería.</li>
          <li>Saber usar programas de sistemas de venta.</li>
          <li>Full time.</li>
        </ul>
      </div>
      <p class="info-text">
        Si cumplís con los requisitos (son excluyentes), envíanos tu currículum por PDF a:
      </p>
      <p class="contact"><a href="https://wa.me/5491157659527">Whatsapp</a></p>
      <p class="info-text">O acércate a nuestro local:</p>
      <p class="address">Av. Eva Perón 4276, Florencio Varela</p>
      <h3 class="footer-title">Distribuidora La Fortaleza</h3>
    </div>
  </div>
</template>


<style scoped>
body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.card {
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 350px;
  width: 100%;
}

.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}

.requirements {
  text-align: left;
  margin-bottom: 15px;
}

.requirements h3 {
  font-weight: bold;
  margin-bottom: 5px;
}

.requirements ul {
  list-style-type: disc;
  padding-left: 20px;
}

.requirements li {
  font-size: 14px;
  margin-bottom: 5px;
}

.info-text {
  font-size: 12px;
  margin-bottom: 5px;
}

.contact {
  font-size: 18px;
  font-weight: bold;
  color: #ff6600;
}

.address {
  font-size: 14px;
  font-weight: bold;
}

.footer-title {
  font-weight: bold;
  margin-top: 15px;
}
</style>