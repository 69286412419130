<script setup>
import { formatNumber, getDate } from "@/utils/utils";
import { onMounted, ref } from "vue";
import { DBService, post } from "../../services/apiReq2";
import { useStore } from "vuex";
import admins from "../../services/admins";

const store = useStore();

const data = ref([]);
onMounted(async () => {
  if (!admins.includes(store.getters?.getUser?.email)) {
    location.href = "/";
  }

  localStorage.setItem(
    "user",
    JSON.stringify({
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwibmFtZSI6Ik1hcnRpbiIsInN1cm5hbWUiOiJDYXRhbGFubyIsImVtYWlsIjoiY2F0YWxhbm9yZXNpbWFydGluQGdtYWlsLmNvbSIsInBob25lIjoiMTIzIiwiZG5pIjoiMTIzIiwicGFzc3dvcmQiOiIkMmIkMTAkdnNmQ1Z3RHlrNkFncURhZEo3R0k5T1Z5Sy4uQlpXamhCdFJJTGROdG1Wa3hLN2lIS0dncmkiLCJyb2xlIjoic3VwZXItYWRtaW4iLCJzaXRlX25hbWUiOiJwYXBlbGVyYS5kYiIsInBhbmVsX2FkbWluIjoxLCJpYXQiOjE3MzcyMzcxNzl9.mgf0uSAvHfE5FnHVcuPiULkae-mb81LPIjwAiAUWd2g",
    })
  );

  let res = await post(
    "/get",
    {
      table: "contact",
      limit: 999,
      page: 1,
    },
    DBService
  );
  data.value = res.data.rows;
});
</script>

<template>
  <div class="table-container">
    <div class="table-wrapper">
      <table class="data-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Nombre</th>
            <th>Email</th>
            <th>Teléfono</th>
            <th>Mensaje</th>
            <th>Fecha</th>
            <th>whatsapp</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in data" :key="index">
            <td>{{ formatNumber(item.id) }}</td>
            <td>{{ item.nombre }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.telefono }}</td>
            <td>{{ item.mensaje }}</td>
            <td>{{ getDate() }}</td>
            <td>
              <a
                  :href="`https://wa.me/${item.telefono}`"
                  target="_blank"
                  class="whatsapp-link"
              >
                {{ item.telefono }}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>
.table-container {
  width: 90%;
  max-width: 100%;
  margin: 5em auto;
  overflow-x: auto;
}

.table-wrapper {
  max-height: 700px;
  overflow-y: auto;
  border: 1px solid #ddd;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  background: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.data-table thead {
  position: sticky;
  top: 0;
  background-color: #f4f4f4;
  z-index: 2;
}

.data-table th,
.data-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.data-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.data-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.data-table tr:hover {
  background-color: #f1f1f1;
}
.whatsapp-link {
  color: #25d366;
  text-decoration: none;
  font-weight: bold;
}

.whatsapp-link:hover {
  text-decoration: underline;
}
</style>
