<template>
  <div class="info-section">
    <a
        v-for="(item, index) in infoItems"
        :key="index"
        @click="setActiveTab(item.key)"
        class="info-item"
    >
      <img :src="item.icon" alt="Icono Compras" class="info-icon">
      <span class="info-text">{{ item.text }}</span>
    </a>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';

const infoItems = [
  { key: 'comoCompro', icon: '/images/icons/bag.png', text: '¿CÓMO COMPRO?' },
  { key: 'comoPago', icon: '/images/icons/bill.png', text: '¿CÓMO PAGO?' },
  { key: 'comoRecibo', icon: '/images/icons/car.png', text: '¿CÓMO LO RECIBO?' }
];

const router = useRouter();
const setActiveTab = (tab) => {
  router.push({ name: 'info', params: { tab } });
};
</script>

<style scoped>
.info-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2px;
  margin: 3px;
}

.info-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  text-decoration: none;
  color: inherit;
  transition: transform 0.2s ease-in-out;
}

.info-item:hover {
  transform: scale(1.1);
}
.info-item:hover {
  transform: scale(1.1);
}

.info-icon {
  font-size: 40px;
  color: #ff6600;
  margin-bottom: 5px;
}

.info-item:hover .info-icon {
  filter: hue-rotate(180deg) saturate(100%) brightness(1.2);
}


.info-item:hover .info-icon {
  fill: #ff6600 !important;
}

.info-text {
  font-weight: bold;
  font-size: 16px;
  color: #333;
}
</style>
