<script setup>
import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import ProductCardOfertas from "@/components/ProductCardOfertas.vue";

const store = useStore();
const showProducts = ref([]);

onMounted( async ()=>{

  let response = await store.dispatch("get", {
    tableName: "vOfertas",
    perPage: 9999999,
    page: 1,
  })

  showProducts.value = response.data.data;
})
</script>

<template>
  <div class="tienda">
    <h1 class="titulo-busqueda">
      Nuestras ofertas
    </h1>

    <Grid class="productos-grid">
      <ProductCardOfertas v-for="(product, key) in showProducts" :key="key" :item="product" />
    </Grid>

  </div>
</template>

<style scoped lang="scss">
.titulo-busqueda {
  margin: 0 ;
  margin-top: 2em;
}
.tienda {
  width: 90%;
  margin: auto;
  padding: 10px;
}

h1 {
  text-align: left;
  width: 90%;
  margin: auto;
  font-size: 1.8rem;
  font-weight: bolder;
  color: #333;
}

.grid {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(4, 1fr);
  margin: 2rem auto;
  padding: 0 1rem;
}
.productos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin: 30px 0;
}

@media screen and (min-width: 1024px) {
  .grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 480px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.special-container {
  img {
    cursor: pointer;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100px;
    height: 115px;
    z-index: 100;
  }
}
</style>