<script setup>
import { Grid } from "digitalpower";
import ProductCard from "@/components/ProductCard.vue";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Input } from "digitalpower";

const store = useStore();
const categorias = ref([]);
const products = ref(null);
const showProducts = ref([]);
const currentPage = ref(1);
const lastPage = ref(1);
const categoria = ref(0);
const orderDir = ref("ASC");
const nombre = ref("");
const codigo = ref("");
const route = useRoute();
const productos = ref([]);

const { categoria_padre, productos_especiales, marca } = route.query;

onMounted(async () => {
  let res = await store.dispatch("get", {
    tableName: "categorias",
    perPage: 100,
    page: 1,
  });
  categorias.value = res?.data?.data?.map((x) => {
    x.categoria = x?.categoria?.split("\r\n")?.[0]?.toLowerCase();
    x.categoria =
      x?.categoria?.charAt(0).toUpperCase() + x?.categoria?.slice(1);
    return x;
  });

  if (categoria_padre) {
    categorias.value = categorias.value?.filter((x) => {
      if (categoria_padre?.toUpperCase() == x?.categoria_padre?.toUpperCase()) {
        return x;
      }
    });
  }

  categorias.value = categorias.value?.map((x) => {
    return { ...x, value: x?.categoria, name: x?.categoria };
  });

  categorias.value.sort((a, b) => {
    if (a.categoria < b.categoria) return -1;
    if (a.categoria > b.categoria) return 1;
    return 0;
  });

  if (route.query?.search) {
    categoria.value = route.query?.categoria;
    nombre.value = route.query?.nombre;
    codigo.value = route.query?.codigo;
    orderDir.value = route.query?.dir ?? "ASC";
  }

  await get();
});

const get = async () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  console.log("Marca",marca);

  products.value = await store.dispatch("getProducts", {
    page: currentPage.value,
    categoria: categoria.value && categoria.value != 0 ? categoria.value : null,
    categoria_padre,
    nombre: nombre.value ?? null,
    codigo: codigo.value ?? null,
    order: "nombre",
    direction: orderDir.value,
    producto_especial: productos_especiales ?? null,
    marca: marca,
  });

  lastPage.value = products.value?.last_page;
  showProducts.value = products.value?.data;
};

const previous = async () => {
  if (currentPage.value <= 1) return;
  console.log("esta es la pagina", currentPage.value);
  currentPage.value--;
  await get();
};

const next = async () => {
  if (currentPage.value >= lastPage.value) return;
  console.log("esta es la pagina", lastPage.value);
  currentPage.value++;
  await get();
};

const handleChange = () => {
  let path = "?search=true";
  if (categoria.value && categoria.value !== 0)
    path += `&categoria=${categoria.value}`;
  if (nombre.value) path += `&nombre=${nombre.value}`;
  if (orderDir.value) path += `&dir=${orderDir.value}`;
  if (categoria_padre) path += `&categoria_padre=${categoria_padre}`;
  location.href = path;
};

watch(categoria, (new_val) => {
  if (
    !new_val ||
    route.query?.categoria?.toUpperCase() === new_val?.toUpperCase()
  )
    return;
  handleChange();
});

watch(orderDir, (new_val) => {
  if (!new_val || route.query?.dir === new_val) return;
  handleChange();
});
</script>
<template>
  <div class="tienda">
    <h1 class="titulo-busqueda" v-if="_search">
      Resultados para la búsqueda: {{ _search }}
    </h1>

    <div class="paginacion paginacion-superior" flex flex-wrap>
      <div flex gapped flex-wrap>
        <Input
          type="select"
          v-model="categoria"
          :options="categorias"
          class="categorias"
        />
        <Input
          type="select"
          v-model="orderDir"
          :options="[
            { name: 'A-Z', value: 'ASC' },
            { name: 'Z-A', value: 'DESC' },
          ]"
          :value="orderDir"
          class="order"
        />
      </div>

      <div class="navegacion mt-5">
        <Input
          background="var(--primary)"
          color="white"
          value="Anterior"
          type="button"
          @click="previous"
        />
        <span>{{ currentPage }}/{{ lastPage }}</span>
        <Input
          background="var(--primary)"
          color="white"
          value="Siguiente"
          type="button"
          @click="next"
        />
      </div>
    </div>

    <Grid width="300">
      <ProductCard
        v-for="(product, key) in showProducts"
        :key="key"
        :item="product"
      />
    </Grid>

    <div class="paginacion paginacion-inferior" flex flex-wrap>
      <div class="navegacion" flex flex-wrap>
        <Input
          background="var(--primary)"
          color="white"
          value="Anterior"
          type="button"
          @click="previous"
        />
        <span>{{ currentPage }}/{{ lastPage }}</span>

        <Input
          background="var(--primary)"
          color="white"
          value="Siguiente"
          type="button"
          @click="next"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.tienda {
  width: 90%;
  margin: auto;
  padding: 20px;
}

.categorias {
  width: 250px;
}

.order {
  width: 100px;
}

span {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}

h1 {
  text-align: left;
  width: 90%;
  margin: auto;
  font-size: 1.8rem;
  font-weight: bolder;
  color: #333;
}

.paginacion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  &.paginacion-superior {
    margin-top: 30px;
  }

  &.paginacion-inferior {
    justify-content: flex-end;
  }
}
.titulo-busqueda {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--primary);
}

.pag-bottom {
  justify-content: flex-end;
}

.paginacion Input[type="button"] {
  background-color: #ff6600;
  color: white;
  border: none;
  padding: 0.3rem 0.8rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.paginacion Input[type="button"]:hover {
  background-color: #ff6600;
}
.filtros select {
  padding: 5px 10px;
  border: 1px solid #bbbbbb;
  cursor: pointer;
  border-radius: 5px;
  background: #fbfbfb;
  color: #000;
}

.navegacion {
  display: flex;
  gap: 10px;
}

.grid {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(4, 1fr);
  margin: 2rem auto;
  padding: 0 1rem;
}
.productos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin: 30px 0;
}

@media screen and (min-width: 1024px) {
  .grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 480px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 800px) {
  .pag-bottom {
    justify-content: center;
  }
}

.special-container {
  img {
    cursor: pointer;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100px;
    height: 115px;
    z-index: 100;
  }
}
</style>
