<script setup>
import {onMounted, ref} from "vue";
import {show} from "../../services/notification";
import {DBService, post} from "../../services/apiReq2";

const nombre = ref("");
const email = ref("");
const telefono = ref("");
const mensaje = ref("");
const fetching = ref(false);

onMounted(() => {
  localStorage.setItem("user", JSON.stringify( {
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwibmFtZSI6Ik1hcnRpbiIsInN1cm5hbWUiOiJDYXRhbGFubyIsImVtYWlsIjoiY2F0YWxhbm9yZXNpbWFydGluQGdtYWlsLmNvbSIsInBob25lIjoiMTIzIiwiZG5pIjoiMTIzIiwicGFzc3dvcmQiOiIkMmIkMTAkdnNmQ1Z3RHlrNkFncURhZEo3R0k5T1Z5Sy4uQlpXamhCdFJJTGROdG1Wa3hLN2lIS0dncmkiLCJyb2xlIjoic3VwZXItYWRtaW4iLCJzaXRlX25hbWUiOiJwYXBlbGVyYS5kYiIsInBhbmVsX2FkbWluIjoxLCJpYXQiOjE3MzcyMzcxNzl9.mgf0uSAvHfE5FnHVcuPiULkae-mb81LPIjwAiAUWd2g'
  }));
})

async function save() {
    fetching.value = true;
    if (!nombre.value || !email.value || !telefono.value || !mensaje.value) {
      show("complete todos los campos");
      fetching.value = false;
      return;
    }

    let response = await post("/insert",{
      "table": "contact",
      "data":{
        "nombre":nombre.value,
        "email":email.value,
        "telefono":telefono.value,
        "mensaje":mensaje.value,
      }
    }, DBService);
    if (response.data.message == "Dato guardado") {
      location.href = "/";
    }

    fetching.value = false;
  }

</script>
<template>
    <body class='body-contact'>
    <div class="contact-section">
      <span class="big-circle"></span>
      <div class="form__content">
        <div class="contact-info">
          <h3 class="title">Mantengámonos en contacto</h3>
          <p class="text">
            No dudes en ponerte en contacto con nosotros a través del formulario que se encuentra a continuación. Nuestro equipo estará encantado de atenderte y te responderemos lo antes posible. Tambien puedes contactarnos por correo electrónico o teléfono.
          </p>

          <div class="info">
            <div class="information">
              <ion-icon name="location-outline"></ion-icon>
              <p>Av. Eva Perón 4276 - Fcio. Varela</p>
            </div>
            <div class="information">
              <ion-icon name="mail-outline"></ion-icon>
              <p>piresmarcelo7@hotmail.com</p>
            </div>
            <div class="information">
              <ion-icon name="call-outline"></ion-icon>
              <p>+54 9 11 2834-7514</p>
            </div>
          </div>

          <div class="social-media">
            <p>Conecta con nosotros:</p>
            <div class="social-icons">
              <a href="https://www.facebook.com/Dist.LFortaleza/" target="_blank" aria-label="Facebook La Fortaleza">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="20" height="20" viewBox="0 0 256 256" xml:space="preserve">
  <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
    <path d="M 51.991 90 V 49.008 h 13.781 l 2.12 -16.049 H 51.991 V 22.739 c 0 -4.632 1.293 -7.791 7.94 -7.791 h 8.417 V 0.637 C 64.25 0.196 60.13 -0.017 56.009 0.001 c -12.212 0 -20.576 7.42 -20.576 21.148 v 11.809 H 21.652 v 16.049 h 13.781 V 90 H 51.991 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #ffffff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
  </g>
</svg>
              </a>
              <a href="https://www.whatsapp.com/catalog/5491128347514/?app_absent=0" target="_blank" aria-label="Whatsapp La Fortaleza">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="20" height="20" viewBox="0 0 256 256" xml:space="preserve">
  <g style="stroke: white; stroke-width: 3; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #fff; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
    <path d="M 0.576 44.596 C 0.573 52.456 2.626 60.129 6.53 66.892 L 0.201 90 l 23.65 -6.201 c 6.516 3.553 13.852 5.426 21.318 5.429 h 0.019 c 24.586 0 44.601 -20.009 44.612 -44.597 c 0.004 -11.917 -4.633 -23.122 -13.055 -31.552 C 68.321 4.65 57.121 0.005 45.188 0 C 20.597 0 0.585 20.005 0.575 44.595 M 14.658 65.727 l -0.883 -1.402 c -3.712 -5.902 -5.671 -12.723 -5.669 -19.726 C 8.115 24.161 24.748 7.532 45.201 7.532 c 9.905 0.004 19.213 3.865 26.215 10.871 c 7.001 7.006 10.854 16.32 10.851 26.224 c -0.009 20.439 -16.643 37.068 -37.08 37.068 h -0.015 c -6.655 -0.004 -13.181 -1.79 -18.872 -5.168 l -1.355 -0.803 l -14.035 3.68 L 14.658 65.727 z M 45.188 89.228 L 45.188 89.228 L 45.188 89.228 C 45.187 89.228 45.187 89.228 45.188 89.228" style="stroke: white; stroke-width: 3; fill: #fff; opacity: 1;" transform=" matrix(1 0 0 1 0 0)" />
    <path d="M 34.038 25.95 c -0.835 -1.856 -1.714 -1.894 -2.508 -1.926 c -0.65 -0.028 -1.394 -0.026 -2.136 -0.026 c -0.744 0 -1.951 0.279 -2.972 1.394 c -1.022 1.116 -3.902 3.812 -3.902 9.296 c 0 5.485 3.995 10.784 4.551 11.529 c 0.558 0.743 7.712 12.357 19.041 16.825 c 9.416 3.713 11.333 2.975 13.376 2.789 c 2.044 -0.186 6.595 -2.696 7.524 -5.299 c 0.929 -2.603 0.929 -4.834 0.651 -5.299 c -0.279 -0.465 -1.022 -0.744 -2.137 -1.301 c -1.115 -0.558 -6.595 -3.254 -7.617 -3.626 c -1.022 -0.372 -1.765 -0.557 -2.509 0.559 c -0.743 1.115 -2.878 3.625 -3.528 4.368 c -0.65 0.745 -1.301 0.838 -2.415 0.28 c -1.115 -0.559 -4.705 -1.735 -8.964 -5.532 c -3.314 -2.955 -5.551 -6.603 -6.201 -7.719 c -0.65 -1.115 -0.069 -1.718 0.489 -2.274 c 0.501 -0.499 1.115 -1.301 1.673 -1.952 c 0.556 -0.651 0.742 -1.116 1.113 -1.859 c 0.372 -0.744 0.186 -1.395 -0.093 -1.953 C 37.195 33.666 35.029 28.154 34.038 25.95" style="stroke: white; stroke-width: 3; fill: #fff; opacity: 1;" transform=" matrix(1 0 0 1 0 0)" />
  </g>
</svg>
              </a>
              <a href="https://www.instagram.com/distribuidora_lfortaleza/" target="_blank" aria-label="Instagram La Fortaleza">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="20" height="20" viewBox="0 0 256 256" xml:space="preserve">
  <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
    <path d="M 62.263 90 H 27.738 C 12.443 90 0 77.557 0 62.263 V 27.738 C 0 12.443 12.443 0 27.738 0 h 34.525 C 77.557 0 90 12.443 90 27.738 v 34.525 C 90 77.557 77.557 90 62.263 90 z M 27.738 7 C 16.303 7 7 16.303 7 27.738 v 34.525 C 7 73.697 16.303 83 27.738 83 h 34.525 C 73.697 83 83 73.697 83 62.263 V 27.738 C 83 16.303 73.697 7 62.263 7 H 27.738 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #ffffff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
    <path d="M 45.255 70.47 c -13.904 0 -25.215 -11.312 -25.215 -25.215 S 31.352 20.04 45.255 20.04 s 25.215 11.312 25.215 25.215 S 59.159 70.47 45.255 70.47 z M 45.255 27.04 c -10.044 0 -18.215 8.171 -18.215 18.215 c 0 10.044 8.171 18.215 18.215 18.215 c 10.044 0 18.215 -8.171 18.215 -18.215 C 63.471 35.211 55.3 27.04 45.255 27.04 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #ffffff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
    <circle cx="70.557" cy="19.936999999999998" r="4.897" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #ffffff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) "/>
  </g>
</svg>
              </a>
            </div>
          </div>
        </div>
        <div class="contact-form">
          <span class="circle one"></span>
          <span class="circle two"></span>
          <form class="contact__form" autocomplete="off">
            <h3 class="title">Contáctanos</h3>
            <div class="input-container">
              <input placeholder="Nombre completo" type="text" name="name" class="input" v-model="nombre" />
            </div>
            <div class="input-container">
              <input placeholder="Email" type="email" name="email" class="input" v-model="email" />
            </div>
            <div class="input-container">
              <input placeholder="Teléfono" type="tel" name="phone" class="input" v-model="telefono" />
            </div>
            <div class="input-container textarea">
              <textarea placeholder="Mensaje..." name="message" class="input" v-model="mensaje"></textarea>
            </div>
            <input @click="save" type="button" value="Enviar" class="btn" v-if="!fetching" />
          </form>
        </div>
      </div>
    </div>
    </body>
</template>


<style scoped>
::placeholder {
  color: #fff;
}

.body-contact {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.contact-section {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 2rem;
    background-color: #fafafa;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .form__content {
    max-height: auto;
    width: 100%;
    max-width: 1120px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
    z-index: 999;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  
  .contact-form {
    background-color: #F8853E;
    position: relative;
  }
  
  .circle {
    border-radius: 50%;
    background: linear-gradient(135deg, transparent 20%, #FDD995);
    position: absolute;
  }
  
  .circle.one {
    width: 130px;
    height: 130px;
    top: 130px;
    right: -40px;
  }
  
  .circle.two {
    width: 80px;
    height: 80px;
    top: 10px;
    right: 30px;
  }
  
  .contact-form:before {
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    background-color: #FAAF6A;
    transform: rotate(45deg);
    top: 50px;
    left: -13px;
  }
  
  .contact__form {
    padding: 2.3rem 2.2rem;
    z-index: 10;
    overflow: hidden;
    position: relative;
  }
  
  .title {
    color: #fff;
    font-weight: 500;
    font-size: 1.7rem;
    line-height: 1;
    margin-bottom: 0.7rem;
  }
  
  .input-container {
    position: relative;
    margin: 1rem 0;
  }
  
  .input {
    width: 100%;
    outline: none;
    border: 2px solid #fafafa;
    background: none;
    padding: 0.6rem 1.2rem;
    color: #fff;
    font-weight: 500;
    font-size: 0.95rem;
    letter-spacing: 0.5px;
    border-radius: 25px;
    transition: 0.3s;
  }
  
  textarea.input {
    padding: 0.8rem 1.2rem;
    min-height: 150px;
    border-radius: 22px;
    resize: none;
    overflow-y: auto;
  }
  
  .input-container label {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    padding: 0 0.4rem;
    color: #fafafa;
    font-size: 0.9rem;
    font-weight: 400;
    pointer-events: none;
    z-index: 900;
    transition: 0.5s;
  }
  
  .input-container.textarea label {
    top: 1rem;
    transform: translateY(0);
  }
  
  .btn {
    width: 95px;
    padding: 0.6rem 1.3rem;
    background-color: #fff;
    border: 2px solid #fafafa;
    font-size: 0.95rem;
    font-weight: 600;
    color: #F8853E;
    line-height: 1;
    border-radius: 25px;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    margin: 0;
  }
  
  .btn:hover {
    background-color: transparent;
    color: #fff;
  }
  
  .input-container .contact-span {
    position: absolute;
    top: 0;
    left: 25px;
    transform: translateY(-50%);
    font-size: 0.8rem;
    padding: 0 0.4rem;
    color: transparent;
    pointer-events: none;
    z-index: 500;
  }
  
  .input-container .contact-span:before,
  .input-container .contact-span:after {
    content: "";
    position: absolute;
    width: 10%;
    opacity: 0;
    transition: 0.3s;
    height: 5px;
    background-color: #F8853E;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .input-container .contact-span:before {
    left: 50%;
  }
  
  .input-container .contact-span:after {
    right: 50%;
  }
  
  .input-container.focus label {
    top: 0;
    transform: translateY(-50%);
    left: 25px;
    font-size: 0.8rem;
  }
  
  .input-container.focus .contact-span:before,
  .input-container.focus .contact-span:after {
    width: 50%;
    opacity: 1;
  }
  
  .contact-info {
    padding: 2.3rem 2.2rem;
    position: relative;
  }
  
  .contact-info .title {
    color: #F8853E;
  }
  
  .text {
    color: #333;
    margin: 1.5rem 0 2rem 0;
    text-align: left;
  }
  
  .information {
    display: flex;
    color: #555;
    margin: 0.8rem 0;
    align-items: center;
    font-size: 0.95rem;
  }

  .information p {
    margin-top: 10px;
    margin-left: 6px;
  }
  
  .social-media {
    padding: 2rem 0 0 0;
  }
  
  .social-media p {
    color: #333;
  }
  
  .social-icons {
    display: flex;
    margin-top: 0.5rem;
  }
  
  .social-icons a {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background: linear-gradient(45deg, #FCC47F, #F8853E);
    color: #fff;
    text-align: center;
    line-height: 35px;
    margin-right: 0.5rem;
    transition: 0.3s;
  }
  
  .social-icons a:hover {
    transform: scale(1.05);
  }
  
  .contact-info:before {
    content: "";
    position: absolute;
    width: 110px;
    height: 100px;
    border: 22px solid #F8853E;
    border-radius: 50%;
    bottom: -77px;
    right: 50px;
    opacity: 0.3;
  }
  
  .big-circle {
    position: absolute;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background: linear-gradient(to bottom, #FCC47F, #F8853E);
    bottom: 50%;
    right: 50%;
    transform: translate(-40%, 38%);
  }
  
  .big-circle:after {
    content: "";
    position: absolute;
    width: 360px;
    height: 360px;
    background-color: #fafafa;
    border-radius: 50%;
    top: calc(50% - 180px);
    left: calc(50% - 180px);
  }
  
  .square {
    position: absolute;
    height: 400px;
    top: 50%;
    left: 50%;
    transform: translate(181%, 11%);
    opacity: 0.2;
  }

  ion-icon {
    position: relative;
    top: 5px !important;
    font-size: 24px;
}
  
  @media (max-width: 850px) {
    .form__content {
      grid-template-columns: 1fr;
      margin-top: 50px;
    }
  
    .contact-info:before {
      bottom: initial;
      top: -75px;
      right: 65px;
      transform: scale(0.95);
    }
  
    .contact-form:before {
      top: -13px;
      left: initial;
      right: 70px;
    }
  
    .square {
      transform: translate(140%, 43%);
      height: 350px;
    }
  
    .big-circle {
      bottom: 75%;
      transform: scale(0.9) translate(-40%, 30%);
      right: 50%;
    }
  
    .text {
      margin: 1rem 0 1.5rem 0;
    }
  
    .social-media {
      padding: 1.5rem 0 0 0;
    }
  }
  
  @media (max-width: 480px) {
    .contact-section {
      margin-top: 100px;
    }

    .container {
      padding: 1.5rem;
    }
  
    .contact-info:before {
      display: none;
    }
  
    .square,
    .big-circle {
      display: none;
    }
  
    .contact__form,
    .contact-info {
      padding: 1.7rem 1.6rem;
    }
  
    .text,
    .information,
    .social-media p {
      font-size: 0.8rem;
    }
  
    .title {
      font-size: 1.15rem;
    }
  
    .social-icons a {
      width: 30px;
      height: 30px;
      line-height: 30px;
    }
  
    .icon {
      width: 23px;
    }
  
    .input {
      padding: 0.45rem 1.2rem;
    }
  
    .btn {
      padding: 0.45rem 1.2rem;
    }

    ion-icon {
      position: relative;
      top: 3px !important;
      font-size: 22px;
    }
  }
</style>