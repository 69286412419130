<script setup>
import ProductCard from "../components/ProductCardSpecial.vue";
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { specialDate } from "@/utils/utils";
import { Image, Carousel, CarouselItem, Input } from "digitalpower";

const store = useStore();
const categorias = ref([]);
const products = ref(null);
const showProducts = ref([]);
const currentPage = ref(1);
const lastPage = ref(1);
const categoria = ref(0);
const orderDir = ref("ASC");
const nombre = ref("");
const codigo = ref("");
const sDate = ref();
const route = useRoute();
const audio = ref();
const audioModal = ref(true);
const router = useRouter();

onMounted(async () => {
  var source = "/audio/christmas.m4a";
  audio.value = document.createElement("audio");
  audio.value.src = source;

  const specialFigure = document.getElementById("special-figure");
  specialFigure.style.display = "none";

  setInterval(() => {
    specialFigure.style.display = "flex";
    setTimeout(() => {
      specialFigure.style.display = "none";
    }, 2000);
  }, 20000);

  getSpecialDate();
  let res = await store.dispatch("get", {
    tableName: "categorias",
    perPage: 100,
    page: 1,
  });

  categorias.value = res?.data?.data
    ?.map((x) => {
      x.categoria = x?.categoria?.split("\r\n")?.[0]?.toLowerCase();
      x.categoria =
        x?.categoria?.charAt(0).toUpperCase() + x?.categoria?.slice(1);
      return x;
    })
    .sort((a, b) => {
      if (a.categoria < b.categoria) return -1;
      if (a.categoria > b.categoria) return 1;
      return 0;
    });

  if (route.query?.search) {
    categoria.value = route.query?.categoria;
    nombre.value = route.query?.nombre;
    codigo.value = route.query?.codigo;
    orderDir.value = route.query?.dir ?? "ASC";
  }
  await get();
});

const get = async () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  //showProducts.value = [];
  products.value = await store.dispatch("getProductsSpecial", {
    page: currentPage.value,
    categoria: categoria.value && categoria.value != 0 ? categoria.value : null,
    nombre: nombre.value ?? null,
    codigo: codigo.value ?? null,
    order: "nombre",
    direction: orderDir.value,
    perPage: 30,
  });

  lastPage.value = products.value?.last_page;
  showProducts.value = products.value?.data;
};

const previous = async () => {
  if (currentPage.value <= 1) return;
  console.log("esta es la pagina", currentPage.value);
  currentPage.value--;
  await get();
};

const next = async () => {
  if (currentPage.value >= lastPage.value) return;
  console.log("esta es la pagina", lastPage.value);
  currentPage.value++;
  await get();
};

const handleChange = () => {
  let path = "?search=true";
  if (categoria.value && categoria.value !== 0)
    path += `&categoria=${categoria.value}`;
  else path = "?search=true";

  if (nombre.value) path += `&nombre=${nombre.value}`;
  if (orderDir.value) path += `&dir=${orderDir.value}`;
  router.push(path);
  get();
};

const getSpecialDate = () => {
  sDate.value = specialDate();
};

const playSpecialAudio = () => {
  /*audio.value.play();
  audio.value.volume = 0.2;*/
  audioModal.value = false;
};

const path = "/images/Banners";

function getImage(resolution, image) {
  return `${path}/${image}/${resolution}.mp4??`;
}

(() => {
  let h = 6; // Número de partículas
  let d = (n) => Math.floor(Math.random() * n); // Generador de números aleatorios
  let k = (c) => {
    let s = document.createElement("style");
    if (!!(window.attachEvent && !window.opera)) {
      s.styleSheet.cssText = c;
    } else {
      s.appendChild(document.createTextNode(c));
    }
    document.getElementsByTagName("head")[0].appendChild(s);
  };

  // Animaciones CSS generadas dinámicamente
  k(
    "@keyframes u{0%{transform:rotate(0deg);}25%{transform:rotate(10deg);}50%{transform:rotate(0deg);}75%{transform:rotate(-10deg);}100%{transform:rotate(0deg);}};"
  );
  k(
    "@keyframes m{0%{margin-top:2vh;opacity:0;}20%{opacity:1.0;margin-top:0vh;margin-left:0vw;transform:rotate(" +
      d(90) +
      "deg);}100%{opacity:0.4;margin-top:100vh;margin-left:" +
      d(4) +
      "vw;transform:rotate(1080deg);}};"
  );

  // Contenedor principal de partículas
  let w = document.createElement("div");
  w.id = "daWorld";
  w.style =
    "animation:u 60s ease-in infinite;position:fixed;top:0;right:0;bottom:0;left:0;pointer-events:none;";
  document.body.appendChild(w);

  // Generador de partículas individuales
  while (h--) {
    let o = document.createElement("div");
    o.style = `pointer-events:none;opacity:0;animation:m ${
      d(14) + 6
    }s ease-in ${d(4000)}ms infinite;z-index:1000;position:fixed;top:${d(
      40
    )}vh;left:${d(100)}vw;font-size:${d(30) + 15}px;color:${
      ["#d00", "#e66", "#fcc"][d(3)]
    };`;
    o.innerHTML = ["🐰", "🥚", "🥕"][d(3)];
    w.appendChild(o);
  }

  return "";
})();
</script>

<template>
  <!--<div class="special-overlay" flex flex-center v-if="audioModal">
  <div :class="sDate?.name" radius-border slide>
      <h1>¡Hola!</h1>
      <p>¡Desde La Fortaleza queremos desearte un <b>Feliz San Valentin</b>!
        <br>
        Por eso te trajimos los mejores productos para que disfrutes con tus seres queridos.
      </p>
      <a radius-border @click="playSpecialAudio">Cliqueá para ver los productos</a>
    </div>
  </div>-->
  <div class="hero">
    <video autoplay loop muted playsinline class="hero-video-mobile">
      <source :src="getImage('mobile', '/Especial')" type="video/mp4" />
    </video>
    <video autoplay loop muted playsinline class="hero-video-pc">
      <source :src="getImage('pc', '/Especial')" type="video/mp4" />
    </video>
    <video autoplay loop muted playsinline class="hero-video-pc-large">
      <source :src="getImage('pc-large', '/Especial')" type="video/mp4" />
    </video>
  </div>
  <div class="special-figure">
    <img
      v-if="sDate?.name === 'christmas'"
      src="/images/papanoel.png"
      alt=""
      id="special-figure"
    />
    <img v-else src="/images/conejo.png" alt="" id="special-figure" />
  </div>
  <div :class="sDate?.name + '-body'">
    <!--<div v-if="sDate?.name === 'christmas'">
          <div class="papanoel">
            &lt;!&ndash; <img src="/images/papanoel.png"> &ndash;&gt;
          </div>
        </div>-->
  </div>

  <body class="body-shop">
    <div class="navegacion mt-5" flex flex-wrap gapped>
      <Input
        background="var(--primary)"
        color="white"
        value="Anterior"
        type="button"
        @click="previous"
      />
      <span flex flex-center>{{ currentPage }}/{{ lastPage }}</span>
      <Input
        background="var(--primary)"
        color="white"
        value="Siguiente"
        type="button"
        @click="next"
      />
    </div>

    <div class="body-shop-container">
      <div class="bulbs-c" v-if="sDate?.name === 'christmas'"></div>

      <div class="cards-container">
        <ProductCard
          v-for="(product, key) in showProducts"
          :key="key"
          :item="product"
        />
      </div>

      <div class="button-container" v-if="showProducts?.length > 0">
        <a
          href="https://api.whatsapp.com/send?phone=541128347514&text=&source=&data=&app_absent="
          target="blank"
        >
          <img
            class="btn-wsp"
            src="../../public/images/whatsapp-sticky.png"
            title="Contáctanos por WhatsApp"
            alt="Clickea aquí para contactarnos"
          />
        </a>
      </div>

      <div class="navegacion mt-5" flex flex-wrap gapped>
        <Input
          background="var(--primary)"
          color="white"
          value="Anterior"
          type="button"
          @click="previous"
        />
        <span flex flex-center>{{ currentPage }}/{{ lastPage }}</span>
        <Input
          background="var(--primary)"
          color="white"
          value="Siguiente"
          type="button"
          @click="next"
        />
      </div>
    </div>
  </body>
</template>

<style scoped lang="scss">
img {
  width: 100%;
}

select {
  margin: 0 !important;
  margin-right: 5px !important;
  //border: none !important;
}

.body-shop-container {
  margin-top: 0px !important;
}

.hero-container {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-easter {
  width: 100%;
  object-fit: cover;
}

.paginacion input[type="button"] {
  background-color: #ff6600;
  color: white;
  border: none;
  padding: 0.3rem 0.8rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.paginacion input[type="button"]:hover {
  background-color: #ff6600;
}

.rabbit {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
}

input {
}

.special-figure {
  pointer-events: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100px;
  height: 100px;
  z-index: 200;

  & > img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100px;
    transform: translate(-100%);
    animation: santa 2s ease-in-out infinite;
  }
}

.special-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  background: rgba(0, 0, 0, 0.4);

  & > .christmas,
  & > .valentines {
    width: 90%;
    max-width: 650px;
    min-height: 250px;
    z-index: 2001;
    padding: 2em;
    margin-top: 5em;

    a {
      display: flex;
      cursor: pointer;
      padding: 0.5em 1em;
      margin-top: 1em;
      background: var(--easter);
      width: fit-content;
    }
  }
}

.special-overlay > .valentines > a {
  background: var(--easter);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.55);
}

.christmas-body,
.valentines-body {
  pointer-events: none !important;
}

.christmas-body,
.valentines-body {
  &::before {
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    content: "";
    position: fixed;
    background: radial-gradient(4px 4px at 2% 10%, var(--easter), transparent),
      radial-gradient(4px 4px at 10% 50%, red, transparent),
      radial-gradient(4px 4px at 15% 30%, red, transparent),
      radial-gradient(4px 4px at 20% 60%, red, transparent),
      radial-gradient(4px 4px at 25% 80%, red, transparent),
      radial-gradient(4px 4px at 30% 40%, red, transparent),
      radial-gradient(4px 4px at 35% 10%, red, transparent),
      radial-gradient(4px 4px at 40% 70%, red, transparent),
      radial-gradient(4px 4px at 45% 20%, red, transparent),
      radial-gradient(4px 4px at 50% 90%, red, transparent),
      radial-gradient(4px 4px at 55% 50%, red, transparent),
      radial-gradient(4px 4px at 60% 30%, red, transparent),
      radial-gradient(4px 4px at 65% 60%, red, transparent),
      radial-gradient(4px 4px at 70% 80%, red, transparent),
      radial-gradient(4px 4px at 75% 40%, red, transparent),
      radial-gradient(4px 4px at 80% 10%, red, transparent),
      radial-gradient(4px 4px at 85% 70%, red, transparent),
      radial-gradient(4px 4px at 90% 50%, red, transparent),
      radial-gradient(4px 4px at 95% 90%, red, transparent),
      radial-gradient(4px 4px at 5% 35%, red, transparent),
      radial-gradient(4px 4px at 15% 75%, red, transparent),
      radial-gradient(4px 4px at 25% 5%, red, transparent),
      radial-gradient(4px 4px at 35% 45%, red, transparent),
      radial-gradient(4px 4px at 45% 85%, red, transparent),
      radial-gradient(4px 4px at 55% 25%, red, transparent),
      radial-gradient(4px 4px at 65% 95%, red, transparent),
      radial-gradient(4px 4px at 75% 65%, red, transparent),
      radial-gradient(4px 4px at 85% 15%, red, transparent),
      radial-gradient(4px 4px at 95% 55%, red, transparent),
      radial-gradient(4px 4px at 100% 25%, #ff3333, transparent);
    animation: snow 8s linear infinite;
  }
}

.christmas-body::after,
.valentines-body::after {
  content: "";
  position: fixed;
  top: 0;
  /* Start offscreen */
  left: 0;
  width: 100vw;
  height: 100%;
  background: radial-gradient(4px 4px at 2% 10%, var(--easter), transparent),
    radial-gradient(4px 4px at 10% 50%, red, transparent),
    radial-gradient(4px 4px at 15% 30%, red, transparent),
    radial-gradient(4px 4px at 20% 60%, red, transparent),
    radial-gradient(4px 4px at 25% 80%, red, transparent),
    radial-gradient(4px 4px at 30% 40%, red, transparent),
    radial-gradient(4px 4px at 35% 10%, red, transparent),
    radial-gradient(4px 4px at 40% 70%, red, transparent),
    radial-gradient(4px 4px at 45% 20%, red, transparent),
    radial-gradient(4px 4px at 50% 90%, red, transparent),
    radial-gradient(4px 4px at 55% 50%, red, transparent),
    radial-gradient(4px 4px at 60% 30%, red, transparent),
    radial-gradient(4px 4px at 65% 60%, red, transparent),
    radial-gradient(4px 4px at 70% 80%, red, transparent),
    radial-gradient(4px 4px at 75% 40%, red, transparent),
    radial-gradient(4px 4px at 80% 10%, red, transparent),
    radial-gradient(4px 4px at 85% 70%, red, transparent),
    radial-gradient(4px 4px at 90% 50%, red, transparent),
    radial-gradient(4px 4px at 95% 90%, red, transparent),
    radial-gradient(4px 4px at 5% 35%, red, transparent),
    radial-gradient(4px 4px at 15% 75%, red, transparent),
    radial-gradient(4px 4px at 25% 5%, red, transparent),
    radial-gradient(4px 4px at 35% 45%, red, transparent),
    radial-gradient(4px 4px at 45% 85%, red, transparent),
    radial-gradient(4px 4px at 55% 25%, red, transparent),
    radial-gradient(4px 4px at 65% 95%, red, transparent),
    radial-gradient(4px 4px at 75% 65%, red, transparent),
    radial-gradient(4px 4px at 85% 15%, red, transparent),
    radial-gradient(4px 4px at 95% 55%, red, transparent),
    radial-gradient(4px 4px at 100% 25%, red, transparent);

  animation: snow2 12s linear infinite;
  z-index: 0;
}

.body-shop {
  padding: 100px;
  min-height: 100vh;
  background: #ffb6c1; /******** COLOR DE FONDO TIENDA ESPECIAL **********/
  background-image: url("/public/images/backgroundpascuas.png");
}

.pagination {
  .pagination-button {
    background: #da3a00;
    color: white;
    padding: 0.5em 1em;
    cursor: pointer;
    transition: var(--duration);

    &:hover {
      transform: scale(1.02);
    }
  }
}
.cards-container {
  gap: 1em;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-content: center;
}

.filter__widget select {
  margin: 0 10px;
  padding: 10px 20px;
  border: 1px solid #bbbbbb;
  cursor: pointer;
  border-radius: 5px;
  background: #fbfbfb;
  color: #000;

  margin-bottom: 8px !important;
}

.filter__widget select:focus {
  outline: none !important;
}

.filter__widget select option {
  font-size: 0.9rem;
}

.search__box {
  align-items: end;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  cursor: pointer;
  width: 300px !important;
  height: 45px;

  padding: 0 !important;

  z-index: 200;
  background: white;

  margin-top: 1em;

  position: -webkit-sticky;
  /* Para Safari */
  position: sticky;
  top: 200px;

  & > form {
    height: 100%;
    position: relative;

    & > input {
      border-radius: 5px;
      z-index: 300;
      position: relative;
    }

    & > span {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.search-input {
  width: 100%;
  border: none !important;
  outline: none !important;
  padding: 11px 10px;
}

.search__box input::placeholder {
  width: 100%;
  color: #000;
}

.search__box span {
  width: 100%;
  color: #000;
}

.search-icon {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.button-container {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  pointer-events: none;
  z-index: 300;
}

.btn-wsp {
  z-index: 1001;
  cursor: pointer;
  height: 70px;
  width: 70px;
  pointer-events: all;
}

.vermas {
  display: flex;
  justify-content: center;
  width: 140px;
  margin: 50px 0;
  background-color: dodgerblue;
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.vermas:hover {
  background-color: deepskyblue;
}

@media only screen and (max-width: 1700px) {
  .cards-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 1500px) {
  .cards-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 1210px) {
  .cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 975px) {
  .filter-container {
    flex-direction: column;
    margin-bottom: 4em;
    margin-top: 3em;
  }

  /*

  .filter__widget {
    margin: 10px 0;
  }

  .filter__widget select {
    margin-left: 0px;
  }

  */

  .search__box {
    width: 100%;
    padding-bottom: 12px;
  }

  .cards-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 768px) {
  /*
  .filter-container {
    margin-top: 50px;
  }

  .filter__widget select {
    padding: 7px 20px;
    font-size: 0.9rem;
    margin-left: 0px;
  }

  */

  .button-container {
    margin-right: 0px;
  }

  .btn-wsp {
    height: 45px;
    width: 45px;
  }
}

@media only screen and (max-width: 670px) {
  .search__box {
    top: 20%;
  }

  .filter-container {
    margin-top: 6em;
  }

  .body-shop {
    padding: 10px;
  }

  .cards-container {
    gap: 1em;
    display: flex;
    flex-wrap: wrap;
  }

  .card_a {
    max-width: 300px;
  }
}

@media only screen and (max-width: 430px) {
  /*
  .filter-container {
  }
  .filter__widget select {
    margin-left: 0px !important;
  }
  */
}

@media only screen and (max-width: 370px) {
  .cards-container {
    gap: 0.5em;
  }

  /*
  .filter__widget select {
    padding: 5px 20px;
  }
  */
}

@keyframes snow {
  from {
    transform: translateY(-50vh);
  }

  to {
    transform: translateY(100vh);
  }
}

@keyframes snow2 {
  from {
    transform: translateY(-100vh);
  }

  to {
    transform: translateY(100vh);
  }
}

@keyframes santa {
  0% {
    transform: translateX(-100%) scale(-1, 1);
  }

  80% {
    transform: translateX(0%) scale(-1, 1);
  }

  100% {
    transform: translateX(-100%) scale(-1, 1);
  }
}

@keyframes retraso {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.pag-text {
  color: white;
}

video {
  width: 100%;
}

@media screen and (max-width: 400px) {
  .hero-video-mobile {
    display: block;
  }

  .hero-video-pc,
  .hero-video-pc-large {
    display: none;
  }
}

@media screen and (min-width: 400px) {
  .hero-video-pc {
    display: block;
  }

  .hero-video-mobile,
  .hero-video-pc-large {
    display: none;
  }
}

@media screen and (min-width: 1920px) {
  .hero-video-pc-large {
    display: block;
  }

  .hero-video-pc,
  .hero-video-mobile {
    display: none;
  }
}
</style>
