<script setup>
import {useStore} from "vuex";
import {computed, onMounted, onUnmounted, ref} from "vue";
import {specialDate} from "@/utils/utils";
import {Input} from "digitalpower";
import admins from "../../services/admins";
import {post} from "../../services/apiReq";
import {useCarritoStore} from "../../services/carrito";

const store = useStore();
const isMenuOpen = ref(false);
const windowWidth = ref(window.innerWidth);
const sDate = ref();
const busqueda = ref("");

const carritoStore = useCarritoStore()

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

const closeMenu = () => {
  isMenuOpen.value = false;
};

const logout = () => {
  store.dispatch("logout");
};
const User = computed(() => store.getters.getUser);

const handleResize = () => {
  windowWidth.value = window.innerWidth;
};

onMounted(async () => {
  getSpecialDate();
  window.addEventListener("resize", handleResize);

  let $search = document.getElementById("busqueda-container");
  $search.addEventListener("keydown", (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      location.href = `/newtienda?search=true&nombre=${busqueda.value}`;
    }
  });

  await carritoStore.get()
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

const getSpecialDate = () => {
  sDate.value = specialDate();
};
</script>

<template>
  <header class="header">
    <a href="/">
      <div class="logo">
        <img
            class="img-logo"
            src="../../public/images/logo.png"
            alt="La Fortaleza logo"
            title="La Fortaleza"
        />
      </div>
    </a>
    <div class="menu-icon" @click="toggleMenu">
      <box-icon name='menu' size="32px"></box-icon>
    </div>
    <nav class="nav" :class="{ open: isMenuOpen }">
      <ul>
        <div id="busqueda-container">
          <Input
              v-model="busqueda"
              id="busqueda"
              type="text"
              placeholder="Buscar"
          />
        </div>
        <li flex flex-center><a href="/">Inicio</a></li>
        <li flex flex-center>
          <a href="/newtienda" class="highlight">Tienda</a>
        </li>
        <li flex flex-center>
          <a href="/newtienda?categoria_padre=herramientas">Utensilios</a>
        </li>
        <li flex flex-center>
          <a href="/newtienda?categoria_padre=ingredientes">Ingredientes</a>
        </li>
        <li flex flex-center><a href="/ofertas">Ofertas</a></li>
        <li flex flex-center>
          <a href="/qr" v-if="admins.includes(store.getters?.getUser?.email)"
          >QR</a
          >
        </li>
        <li flex flex-center>
          <a v-if="User" @click="logout" href="/login">Cerrar Sesion</a>
        </li>
        <!--        <li flex flex-center><a href="/">Moldes</a></li>-->
      </ul>
    </nav>
    <div class="actions">
      <button class="icon-button" title="Iniciar Sesión" v-if="!User">
        <a href="/login">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);"><path d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2 7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17z"></path></svg>
        </a>
      </button>
      <button class="icon-button" title="Mis compras" v-if="User">
        <a href="/compras">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);"><path d="M5 22h14c1.103 0 2-.897 2-2V9a1 1 0 0 0-1-1h-3V7c0-2.757-2.243-5-5-5S7 4.243 7 7v1H4a1 1 0 0 0-1 1v11c0 1.103.897 2 2 2zM9 7c0-1.654 1.346-3 3-3s3 1.346 3 3v1H9V7zm-4 3h2v2h2v-2h6v2h2v-2h2l.002 10H5V10z"></path></svg>
        </a>
      </button>
      <button class="icon-button cart" title="Carrito" v-if="User">
        <a class="carrito-anchor" href="/carrito" flex flex-center>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);"><path d="M21.822 7.431A1 1 0 0 0 21 7H7.333L6.179 4.23A1.994 1.994 0 0 0 4.333 3H2v2h2.333l4.744 11.385A1 1 0 0 0 10 17h8c.417 0 .79-.259.937-.648l3-8a1 1 0 0 0-.115-.921zM17.307 15h-6.64l-2.5-6h11.39l-2.25 6z"></path><circle cx="10.5" cy="19.5" r="1.5"></circle><circle cx="17.5" cy="19.5" r="1.5"></circle></svg>
          
          <span class="cart-qty">{{ carritoStore.cantidad }}</span>

        </a>
      </button>
    </div>
  </header>
</template>

<style scoped>
.valentines {
  background: unset !important;
  color: var(--valentines) !important;
}

#carrito-anchor {
  position: relative;

  span {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

#busqueda {
  height: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 10;
}

.logo img {
  height: 70px;
}

.nav {
  display: flex;
}

.nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

input {
  border-radius: 4px;
}

.nav li {
  margin: 0 1.5rem;
}

.nav a {
  text-decoration: none;
  color: #555;
  font-size: 0.9rem;
  font-weight: 500;
  transition: color 0.3s;
}

.nav a:hover {
  color: #ff6600;
}

.nav a.highlight {
  color: #ff6600;
  font-weight: bold;
}

.especial:hover {
  color: red !important;
}

.actions {
  display: flex;
  align-items: center;
}

.icon-button {
  background: none;
  border: 1px solid #ddd;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  cursor: pointer;
  transition: all 0.3s;
}

.icon-button:hover {
  background-color: #f2f2f2;
}

.icon-button i {
  font-size: 2rem;
  color: #555;
}

.cart {
  position: relative;
}

.cart-qty {
  padding-left: 5px;
}

.cart-count {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #ff6600;
  color: white;
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Menú hamburguesa */
.menu-icon {
  display: none;
  cursor: pointer;
}

.nav.open {
  position: absolute;
  top: 60px;
  right: 0;
  background: white;
  flex-direction: column;
  width: 200px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  z-index: 1000;
}

.nav.open ul {
  flex-direction: column;
}

.nav.open li {
  margin: 1rem 0;
}

@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  .nav {
    display: none;
  }

  .nav.open {
    display: flex;
  }
}

@media (max-width: 1288px) {
  .menu-icon {
    display: block;
  }

  .nav {
    display: none;
  }

  .nav.open {
    display: flex;
  }
}

#busqueda-container {
  position: absolute;
}

@media (min-width: 1288px) {
  #busqueda-container {
    position: static;
    margin: 10px auto;
    text-align: center;
  }
}
</style>
