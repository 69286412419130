<script setup>
import CategorySection from "@/components/CategorySection.vue";
import News from "@/components/News.vue";
import InfoSection from "@/components/InfoSection.vue";
import Brands from "@/components/Brands.vue";
</script>

<script>
import Helmet from '../components/Helmet.vue';
import HeroSection from "@/components/HeroSection.vue";
import Carousel from '@/components/Carousel.vue';
import {ref} from "vue";

export default {
  components: {
    Helmet,
    HeroSection,
    Carousel,
  }
};

const info = ref({
  comoCompro: 'Paso 1: Añade productos al carrito.',
  comoPago: 'Paso 2: Realiza el pago con tarjeta o PayPal.',
  comoRecibo: 'Paso 3: Recibe tu pedido en casa.'
});

const setActiveTab = (tab) => {
  switch (tab) {
    case 'comoCompro':
      info.value = { comoCompro: 'Paso 1: Añade productos al carrito.' };
      break;
    case 'comoPago':
      info.value = { comoPago: 'Paso 2: Realiza el pago con tarjeta o PayPal.' };
      break;
    case 'comoRecibo':
      info.value = { comoRecibo: 'Paso 3: Recibe tu pedido en casa.' };
      break;
  }
};

function GoTo (link) {
  let $a = document.createElement("a");
  $a.href = link;
  $a.target = "_blank";
  document.body.append($a);
  $a.click();
}
</script>


<template>
  <div class="home">
    <Helmet :title="'Inicio'" />
    <HeroSection title="La Fortaleza Inicio" />
   <div class="content">
     <h4 class="work mb-5"><a href="https://lafortaleza.com.ar/workwithus">¡¿ TE GUSTARIA SER PARTE DE NUESTRO STAFF ?!</a></h4>
   </div>
    <InfoSection @updateTab="setActiveTab" />
    <!--<Book /> -->
    <!--<div>
      <TimeTable />
    </div>
    -->
    <CategorySection/>

    <Brands />
    <div class="content">
      <h2 class="mb-5 wilton">Todos los productos WILTON con un 50 % OFF</h2>
        <News/>
    </div>

    <div class='button-container'>
      <a href="https://api.whatsapp.com/send?phone=541128347514&text=&source=&data=&app_absent=" target='blank'>
        <img class='btn-wsp' src="../../public/images/whatsapp-sticky.png" title="Contáctanos por WhatsApp"
          alt="Clickea aquí para contactarnos" />
      </a>
    </div>
  </div>
</template>


<style scoped>
.wilton {
  width: 90%;
  margin: auto;
  text-align: center;
}

.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.work {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ff6804;
}

.work:hover {
  text-decoration: underline;
}

/* WhatsApp Btn */

.button-container {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  pointer-events: none;
  z-index: 300;
}

.btn-wsp {
  z-index: 1001;
  cursor: pointer;
  height: 70px;
  width: 70px;
  pointer-events: all;
}

@media only screen and (max-width: 1023px) {
  .button-container {
    margin-right: 0px;
  }

  .btn-wsp {
    height: 45px;
    width: 45px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.especial {
  width: max-content;
  margin: 0 auto;
  padding: 20px;
}
</style>
