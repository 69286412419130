<script setup>
import {imageHandler} from "../../services/errors";
import {backendUrl, post} from "../../services/apiReq";
import {useStore} from "vuex";
import {Swiper} from "swiper";
import {Autoplay} from "swiper/modules";
import "swiper/swiper-bundle.css";
import {onMounted, reactive, ref, watch} from "vue";
import {defaultImageProduct} from "@/utils/utils";
import {show} from "../../services/notification";
import {Input} from "digitalpower";
import Modal from "./Products/Modal.vue";
import {useCarritoStore} from "../../services/carrito";

Swiper.use(Autoplay);

const props = defineProps(["item"]);
const store = useStore();

const cartCounter = ref(1);
const carousel = reactive({});
const carouselInterno = reactive({});

const carritoStore = useCarritoStore()

const modal = ref(false);

const calculatePrice = (product) => {
  if (product?.ofertas || product?.descuento || product?.producto_especial) {
    const price = product?.precio;
    const discount =
        product?.ofertas?.descuento ??
        product?.descuento ??
        product?.producto_especial;

    const dPrice = price - (discount * price) / 100;
    return dPrice?.toFixed(2);
  } else return product?.precio;
};

const addToCart = async (producto, _observacion, _cartCounter) => {
  let res = await post("/carrito", {
    user_id: store?.getters?.getUser?.id,
    cantidad: _cartCounter ? _cartCounter : cartCounter.value,
    producto_codigo: producto?.codigo,
    observacion: _observacion,
  });
  show("producto añadido");
  modal.value = false;

  await carritoStore.get();
};

async function decrement() {
  if (cartCounter.value <= 1) return;
  cartCounter.value--;
}

async function increment() {
  cartCounter.value++;
}

onMounted(() => {
  carousel.value = new Swiper(".swiper-container", {
    slidesPerView: "auto",
    autoplay: {
      delay: 1300,
      disableOnInteraction: false,
    },
  });
});

watch(modal, (value) => {
  if (!value) return;

  setTimeout(() => {
    carouselInterno.value = new Swiper(".swiper-container2", {
      slidesPerView: "auto",
      autoplay: {
        delay: 1300,
        disableOnInteraction: false,
      },
    });
  }, 1000);
});
</script>

<template>
  <Modal
      :item="item"
      v-if="modal"
      :addToCart="addToCart"
      @close="
      () => {
        modal = false;
      }
    "
  ></Modal>
  <a
      class="card_a"
      @click="
      () => {
        modal = true;
      }
    "
      target="_blank"
  >
    <div class="card" fade>
      <div class="no-stock-banner" v-if="item?.stock" shadow slideRight>
        <p>SIN STOCK</p>
      </div>
      <div
          class="no-stock-banner"
          v-if="
          (item?.ofertas || item?.descuento || item?.producto_especial) &&
          !item?.stock
        "
          shadow
          slideRight
      >
        <p>
          {{
            item?.ofertas?.descuento ??
            item?.descuento ??
            item?.producto_especial
          }}% OFF
        </p>
      </div>
      <!--<div class="deco-container">
        <img class="card-decoration" src="/images/navidad.png" />
      </div>-->
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(image, key) in item?.images" :key>
            <img
                :src="`${backendUrl}/files/2/${image}`"
                alt="La Fortaleza Producto"
                @error="defaultImageProduct"
            />
            <div
                class="no-stock-banner frio"
                v-if="item?.frio"
                shadow
                slideRight
            >
              <p>Necesita cadena de frio {{ item?.frio }}</p>
            </div>
          </div>
        </div>
      </div>
      <!--
    <div class="card__img">
      <img :title="item ? item.nombre : ''" :src="`${backendUrl}/files/2/${item?.codigo?.trim()}.png`" @error="imageHandler($event)" alt="Productos La Fortaleza">
    </div>
    -->
      <div class="card__content">
        <h4 class="card-title" nopointer>{{ item?.nombre }}</h4>
        <small id="marca" class="marca">{{ item?.marca }}</small> <br/>
        <div
            class="addCart"
            v-if="store?.getters?.getUser?.id && !item?.stock"
            flex-center
            gapped
        >
        <box-icon @click.stop="addToCart(item)" class="buy" type='solid' name='cart-download'></box-icon>
          <div class="cart-counter" flex-center gapped>
            <a @click.stop="decrement" flex-center radius-border>-</a>
            <p>{{ cartCounter }}</p>
            <a @click.stop="increment" flex-center radius-border>+</a>
          </div>
        </div>
        <p class="card-price" v-if="store?.getters?.getUser?.id">
          ${{ calculatePrice(item) }}
        </p>
        <div class="orange" v-else>
          <a class="login-to-see" href="/login"
          ><small>Iniciar sesión para ver detalles</small></a
          >
        </div>
      </div>
    </div>
  </a>
</template>

<style scoped lang="scss">

.buy {
  background: orangered;
  width: 30.6px;
  height: 30.6px;
  padding: 6px;
  border-radius: 5px;
}
.cart-counter {
  a {
    background: orangered;
    color: white;
    height: 30px;
    width: 30px;
    transition: var(--duration);

    &:hover {
      border-radius: 8px;
    }
  }
}

.frio {
  top: unset !important;
  right: unset !important;
  bottom: 0;
}

.modal-product-container {
  justify-content: space-evenly;
}

.modal-product-image {
  align-items: start;
  width: 300px !important;
  overflow: hidden;
}

.modal-product-info {
  width: 400px;
}

.swiper-container2 {
  width: 300px;
  height: 300px;

  .swiper-wrapper {
    width: 100%;
    height: 100%;

    .swiper-slide {
      width: 100%;
      height: 100%;

      img {
        width: 300px;
        height: 300px;
        max-height: 300px;
      }
    }
  }
}

.swiper-container {
  width: 100%;
  height: 100%;

  .swiper-wrapper {
    width: 100%;
    height: 100%;

    .swiper-slide {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 306px;

        max-height: 306px;
      }
    }
  }
}

.orange {
  color: orangered;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 1em;
}

.card_a {
  height: 460px !important;
  margin-top: 5em;
  max-width: 30ch;
  display: block;
  width: 100%;
}

.card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  color: #000;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  max-width: 30ch;
  height: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  border: #eee 1px solid;
  box-shadow: #e5e5e5 4px 5px 5px 4px;
  transition: transform 500ms ease;
  position: relative;
}

.card:hover,
.card:focus-within {
  transform: scale(1.02);
  border: rgba(88, 89, 89, 0.49) 1px solid;
}

.card-price {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 1em;
}

.card__img {
  margin: 0px;
  padding: 0px;
  height: 160px;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

img {
  width: 100%;
  object-fit: cover;
}

.card__img img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
}

.card-title {
  text-decoration: none;
}

.card-title:hover {
  text-decoration: underline !important;
}

.card__content {
  padding: 1.2rem 0.9rem;
  margin-top: 4px;
}

.card-body__content {
  display: flex;
  justify-content: space-between;
  padding: 28px 0;
}

.card-price {
  color: #0db60a;
  font-weight: bold;
}

.card__btn {
  cursor: pointer;
  display: inline;
  color: #fff;
  background-color: #666;
  margin-top: 10px;
  padding: 0.5em 1.5em;
  border-radius: 1em;
  transition: 0.2s;
  position: absolute;
  bottom: 6px;
  left: 50%;
  transform: translateX(-50%);
}

.card__btn:hover,
.card__btn:focus {
  background-color: #f8853e;
}

.no-stock-banner {
  position: absolute;
  right: 0;
  top: 0;
  width: fit-content;
  background: #da3a00;
  padding: 0.2em 1em 0.2em 0.5em;
  z-index: 5;

  p {
    width: 100%;
    text-align: right;
    color: white;
  }
}

.deco-container {
  position: absolute;
  z-index: 4;
  /* Asegúrate de que esté por encima del resto del contenido */
  top: 0;
  /* Siempre en la parte superior */
  bottom: 80%;
  left: 50%;
  /* Centrado horizontalmente */
  transform: translateX(-50%);
  /* Ajuste para centrar */
  display: flex;
  align-items: center;
  width: max-content;
  /* Ajusta el ancho según el diseño */
  height: auto;
}

.card-decoration {
  width: 260px;
  /* Mantén el ancho según el contenedor */
  height: 270px;
  /* Ajusta la altura automáticamente */
}

.addCart {
  width: fit-content;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 1em;

  .icon {
    width: 35px;
    height: 35px;
    padding: 0.9em;
    border-radius: 5px;
    color: white;
    background: orangered;
    transition: var(--duration);

    &:hover {
      border-radius: 8px;
    }
  }
}

.marca {
  display: block;
  margin-bottom: 2em;
  color: #da3a00;
}

.login-to-see {
  columns: #da3a00;
  font-size: 1.04rem;
}

.orange {
  border: none;
  padding: 0.3em;
  color: white;
  margin-bottom: 0.5em;
}

.discount {
  color: gray !important;
  font-size: 0.9em !important;
}

.discount-price {
  text-decoration: line-through;
}

.itemdetail__container {
  padding: 120px 350px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.product__price {
  font-size: 20px;
  color: #08a208;
  font-weight: 600;
}

#observacion {
  height: 150px;
  width: 350px;
  resize: none;
  margin-top: 2em;
  border-radius: 10px;
  padding: 1em;

  &:focus {
    outline: none;
  }
}

.orangered {
  color: orangered;
}

@media screen and (max-width: 1200px) {
  .modal-product-container {
    justify-content: center;
    gap: 3em;
  }

  .modal-product-image {
    width: 90%;
  }
}

@media only screen and (max-width: 670px) {
  .card_a {
    height: auto !important;
    margin-top: 2em;
    max-width: 300px;
  }

  .card {
    max-width: 300px;
  }

  .deco-container {
    position: absolute;
    z-index: 4;
    /* Asegúrate de que esté por encima del resto del contenido */
    top: 0;
    /* Siempre en la parte superior */
    bottom: 86%;
    left: 50%;
    /* Centrado horizontalmente */
    transform: translateX(-50%);
    /* Ajuste para centrar */
  }

  .card-decoration {
    width: 200px;
    height: 200px;
  }

  .card__img img {
    width: 100%;
    height: auto;
  }

  .card-title {
    font-size: 16px;
  }

  .card-body__content {
    padding: 16px 0 30px 0;
  }

  .card-price {
    margin: 0.5em;
  }

  .addCart {
    margin: 0.5em;
  }

  .orange {
    margin: 0.5em;
  }

}

@media only screen and (min-width: 560px) and (max-width: 670px) {
  .card_a {
    height: auto !important;
    margin-top: 2em;
    max-width: 300px;
    margin-left: 0;
    margin-right: 0;
  }

  .card {
    max-width: 300px;
  }

  .card__img img {
    width: 100%;
    height: auto;
  }

  .card-title {
    font-size: 16px;
  }

  .card__content {
    margin-top: 48px;
    padding: 1.8rem 0.8rem;
  }

  .card-body__content {
    padding: 16px 0 30px 0;
  }

  .card-price {
    margin: 0.5em;
  }

  .addCart {
    margin: 0.5em;
  }

  .orange {
    margin: 0.5em;
  }
}

@media only screen and (max-width: 400px) {
  .card_a {
    height: auto !important;
    margin-top: 1em;
    max-width: 300px;
  }

  .card {
    padding: 0.5rem 0 0;
    max-width: 300px;
  }

  .card__img img {
    width: 100%;
    height: auto;
  }

  .card-title {
    font-size: 14px;
  }

  .card__content {
    margin-top: 15px;
    padding: 1rem 0.5rem;
  }

  .card-body__content {
    padding: 16px 0 20px 0;
  }

  .card-price,
  .addCart {
    margin: 0.5em;
  }

  .orange {
    margin: 0.5em;
  }

}
</style>
