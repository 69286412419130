<template>
  <div class="image-grid">
    <div
        class="image-item"
        v-for="(item, index) in items"
        :key="index"
    >
      <a :href="item.link" class="image-link">
        <img :src="item.image" :alt="item.title" class="image"/>
        <div class="overlay">
          <h2 class="title">{{ item.title }}</h2>
          <button class="btn">{{ item.buttonText }}</button>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageGrid",
  data() {
    return {
      items: [
      {
          title: "SECCION ESPECIAL",
          buttonText: "COMPRAR",
          link: "/especial",
          image: "/images/pasteleria-pascua.jpg",
        },
        {
          title: "OFERTAS",
          buttonText: "COMPRAR",
          link: "/ofertas",
          image: "/images/pasteleria6.jpg",
        },
        {
          title: "UTENSILIOS",
          buttonText: "COMPRAR",
          link: "/newtienda?search=true&dir=ASC&categoria_padre=HERRAMIENTAS",
          image: "/images/pasteleria4.webp",
        },
        {
          title: "INGREDIENTES",
          buttonText: "COMPRAR",
          link: "/newtienda?search=true&dir=ASC&categoria_padre=INGREDIENTES",
          image: "/images/pasteleria3.jpg",
        },
      ],
    };
  },
};
</script>

<style scoped>
.image-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 0 20px;
  margin: 3em;
}


.image-link {
  display: block;
  text-decoration: none;
}

.image-item {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.image {
  width: 90%;
  height: auto;
  border-radius: 8px;
}

.overlay {
  position: absolute;
  top: 0;
  width: 90%;
  height: 100%;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.image-item:hover .overlay {
  opacity: 1;
}

.title {
  font-size: 1.2em;
  margin: 0 0 10px;
  text-align: center;
}

.btn {
  background-color: orange;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 4px;
}

.btn:hover {
  background-color: darkorange;
}
@media screen and (max-width: 768px) {
  .image-grid {
    grid-template-columns: 1fr;
    gap: 15px;
    padding: 0 10px;
  }

  .image {
    width: 100%;
  }

  .overlay {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .image-grid {
    gap: 10px;
    padding: 0 5px;
  }

  .title {
    font-size: 1em;
  }

  .btn {
    font-size: 0.9em;
    padding: 8px 15px;
  }
}

</style>
