<script>
  export default {
    data() {
      return {
        images: [
        { src: "/images/marcas/logo-decor.jpg", alt: "arcor logo", link: "/newtienda?marca=decor" },
        { src: "/images/marcas/mantecol-logo.jpg", alt: "mantecol logo", link: "/newtienda?marca=mondelez" },
        { src: "/images/marcas/logo-lodiser.png", alt: "lodiser logo", link: "/newtienda?marca=lodiser" },
        { src: "/images/marcas/arcor-logo.jpg", alt: "arcor logo", link: "/newtienda?marca=arcor" },
        { src: "/images/marcas/ledevit-logo.jpg", alt: "ledevit logo", link: "/newtienda?marca=ledevit" },
        { src: "/images/marcas/logo-argenfrut.png", alt: "argenfrut logo", link: "/newtienda?marca=argenfrut" },
        { src: "/images/marcas/logo-ballina.png", alt: "ballina logo", link: "/newtienda?marca=ballina" },
        { src: "/images/marcas/logo-cañuelas.jpg", alt: "cañuelas logo", link: "/newtienda?marca=cañuelas" },
        { src: "/images/marcas/chocolart.webp", alt: "chocolart", link: "/newtienda?marca=chocolart" },
        { src: "/images/marcas/logo-cooper.png", alt: "cooper logo", link: "/newtienda?marca=cooper" },
        { src: "/images/marcas/logo-decormagic.jpg", alt: "decormagic logo", link: "/newtienda?marca=decormagic" },
        { src: "/images/marcas/logo-dewey.png", alt: "dewey logo", link: "/newtienda?marca=dewey" },
        { src: "/images/marcas/logo-doslembas.png", alt: "doslembas logo", link: "/newtienda?marca=dos lemba" },
        { src: "/images/marcas/logo-gelbrix.jpg", alt: "gelbrix logo", link: "/newtienda?marca=gelbrix" },
        { src: "/images/marcas/logo-labotica.png", alt: "labotica logo", link: "/newtienda?marca=la botica" },
        { src: "/images/marcas/logo-mapsa.png", alt: "mapsa logo", link: "/newtienda?marca=mapsa" },
        { src: "/images/marcas/logo-milkey.png", alt: "milkey logo", link: "/newtienda?marca=milkey" },
        { src: "/images/marcas/logo-nutella.png", alt: "nutella logo", link: "/newtienda?marca=nutella" },
        { src: "/images/marcas/logo-parpen.png", alt: "parpen logo", link: "/newtienda?marca=parpen" },
        { src: "/images/marcas/logo-pastelar.jpg", alt: "pastelar logo", link: "/newtienda?marca=pastelar" },
        { src: "/images/marcas/logo-telaflex.jpg", alt: "telaflex logo", link: "/newtienda?marca=telaflex" },
        { src: "/images/marcas/logo-topclass.png", alt: "topclass logo", link: "/newtienda?marca=top class" },
        { src: "/images/marcas/logo-vacalin.jpg", alt: "vacalin logo", link: "/newtienda?marca=vacalin" },
        { src: "/images/marcas/logo-wilton.jpg", alt: "wilton logo", link: "/newtienda?marca=wilton" },
        { src: "/images/marcas/logo-fincaellazo.jpg", alt: "finca el lazo logo", link: "/newtienda?marca=el lazo" },
        { src: "/images/marcas/logo-barifrucor.png", alt: "bari frucor logo", link: "/newtienda?marca=frucor" },
        //{ src: "/images/marcas/logo-labanda.png", alt: "la banda logo", link: "/newtienda" },
        { src: "/images/marcas/logo-ravana.jpg", alt: "ravana logo", link: "/newtienda?marca=ravana" },
        { src: "/images/marcas/logo-fleibor.png", alt: "fleibor logo", link: "/newtienda?marca=fleibor" },
        { src: "/images/marcas/aguila-logo.jpg", alt: "Aguila logo", link: "/newtienda?marca=aguila" },
        { src: "/images/marcas/calsa.jpg", alt: "calsa logo", link: "/newtienda?marca=calsa" },
        { src: "/images/marcas/danica.jpg", alt: "danica logo", link: "/newtienda?marca=danica" },
        { src: "/images/marcas/deliciasdoradas.png", alt: "delicias doradas logo", link: "/newtienda?marca=delicia" },
        { src: "/images/marcas/femag.jpg", alt: "femag logo", link: "/newtienda?marca=femag" },
        { src: "/images/marcas/gentleman.jpg", alt: "gentleman logo", link: "/newtienda?marca=gentleman" },
        { src: "/images/marcas/kingdust.png", alt: "king dust logo", link: "/newtienda?marca=king dust" },
        { src: "/images/marcas/lacordobesa.png", alt: "la cordobesa logo", link: "/newtienda?marca=cordobesa" },
        { src: "/images/marcas/milkaut.png", alt: "milkaut logo", link: "/newtienda?marca=milkaut" },
        { src: "/images/marcas/mondelz.png", alt: "mondelez logo", link: "/newtienda?marca=mondelez" },
        { src: "/images/marcas/natura.png", alt: "natura logo", link: "/newtienda?marca=natura" },
        { src: "/images/marcas/olivarescesar.jpg", alt: "olivares del cesar logo", link: "/newtienda?marca=olivares" },
        { src: "/images/marcas/richs.png", alt: "richs logo", link: "/newtienda?marca=richs" },
        { src: "/images/marcas/tanto.svg", alt: "tanto logo", link: "/newtienda?marca=tanto" },
        ],
      };
    },
    methods: {
      GoTo(link) {
        let anchor = document.createElement("a");
        anchor.href = link;
        anchor.target = "_blank";
        document.body.append(anchor);
        anchor.click();
      },
    },
  };
</script>


<template>
    <div class="brands-container">
      <div class="brands-text-container">
        <h2 class="brands">Marcas</h2>
        <p class="brands-p">Encontrá todas las marcas que necesitas con nosotros</p>
      </div>
      <div class="slider-container">
        <ul class="image-grid">
          <li v-for="(image, index) in images" :key="index">
            <img class="image-item" :src="image.src" :alt="image.alt" @click="GoTo(image.link)" />
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  
  <style scoped>
  .brands-container {
    display: flex;
    flex-direction: column;
    padding: 1px 100px 0px 100px;
    width: 90%;
    margin: auto;
  }
  
  .brands-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .brands {
    text-transform: uppercase;
    font-family: italic;
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .brands-p {
    font-size: 15px;
    margin-bottom: 30px;
  }
  
  .slider-container {
    position: relative;
  }
  
  .image-grid {
    display: grid;
    grid-template-columns: repeat(7, 4fr);
    gap: 18px;
    list-style: none;
    margin-bottom: 30px;
    overflow-x: auto;
    scrollbar-width: none;
  }
  
  .image-grid::-webkit-scrollbar {
    display: none;
  }
  
  .image-item {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 20px;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  
  @media only screen and (max-width: 1023px) {
    .brands {
      font-size: 24px;
      margin-top: 28px;
    }
  
    .brands-p {
      font-size: 16px;
    }
  
    .brands-container {
      padding: 10px 0;
    }
  
    .image-grid {
      gap: 10px;
      margin-bottom: 15px;
      display: flex;
      scroll-snap-type: x mandatory;
      overflow-x: scroll;
    }
  
    .image-item {
      width: 180px;
      height: 180px;
      scroll-snap-align: start;
    }
  }
  </style>
  