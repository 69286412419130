<script setup>
import {onMounted, ref} from "vue";
import {DBService, post} from "../../services/apiReq2";
import {Input} from 'digitalpower';
import {show} from "../../services/notification";
import {useRoute} from "vue-router";
import {getDate} from "@/utils/utils";

const nombre = ref("");
const apellido = ref("");
const email = ref("");
const telefono = ref("");
const dni = ref("")
const fetching = ref(false);
const route = useRoute()
const {token} = route.query;

onMounted(async () => {
  localStorage.setItem("user", JSON.stringify( {
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwibmFtZSI6Ik1hcnRpbiIsInN1cm5hbWUiOiJDYXRhbGFubyIsImVtYWlsIjoiY2F0YWxhbm9yZXNpbWFydGluQGdtYWlsLmNvbSIsInBob25lIjoiMTIzIiwiZG5pIjoiMTIzIiwicGFzc3dvcmQiOiIkMmIkMTAkdnNmQ1Z3RHlrNkFncURhZEo3R0k5T1Z5Sy4uQlpXamhCdFJJTGROdG1Wa3hLN2lIS0dncmkiLCJyb2xlIjoic3VwZXItYWRtaW4iLCJzaXRlX25hbWUiOiJwYXBlbGVyYS5kYiIsInBhbmVsX2FkbWluIjoxLCJpYXQiOjE3MzcyMzcxNzl9.mgf0uSAvHfE5FnHVcuPiULkae-mb81LPIjwAiAUWd2g'
  }));

  let resposne = await post('/get', {
    table: 'tokens',
    where: [
      {condition: {data: token}, strict: true},
      {condition: {status: 'unused'}}
    ]
  }, DBService)
  if (resposne.data?.rows?.length <= 0) location.href = '/';

})

async function save() {
  if (!nombre.value
      || !email.value
      || !apellido.value
      || !telefono.value
      || !dni.value) {
    show("Por favor, complete todos los campos.");
    return;
  }
  fetching.value = true;

  if (dni.value.length < 8) {
    show("El DNI debe tener exactamente 8 caracteres.");
    return;

  }
/*
  let checkResponse = await post('/get', {
    table: 'formularios_sorteo2',
    page: 1,
    limit: 999,
    where: [
      {condition: {dni: dni.value}}
    ]
  }, DBService);

  if (checkResponse.data?.rows?.length > 0) {
    show("Ya se encuentra registrado el dni ingresado.");
    fetching.value = false;
    return;
  }
*/
  let response = await post('/insert', {
    "table": "formularios_sorteo2",
    "data": {
      "nombre": nombre.value,
      "apellido": apellido.value,
      "email": email.value,
      "telefono": telefono.value,
      "dni": dni.value,
      "date": getDate(),
    }
  }, DBService);

  if (response.data?.message == 'Dato guardado') {
    location.href = `/formulario/comprobante?sorteo=2&id=${dni.value}`;

    let resp = await post('/update', {
      table: 'tokens',
      tablename: 'tokens',
      where: {
        data: token
      },
      data: {
        status: 'used'
      }
    }, DBService)
  }
  fetching.value = false;
}

</script>

<template>
  <div class="content">
    <div class="form">
      <h3>Superaste la compra de $ 50.000, completa el formulario para participar por un viaje a las Cataratas</h3>
      <Input class="input" label="Nombre" placeholder="Nombre" v-model="nombre" />
      <Input class="input" label="Apellido" placeholder="Apellido" v-model="apellido" />
      <Input type="email" class="input" label="Email" placeholder="Email" v-model="email" />
      <Input class="input" label="Telefono" placeholder="Telefono" v-model="telefono" />
      <Input type="number" class="input" label="DNI" placeholder="12..." v-model="dni" />
      <div class="button-container">
        <Input
            v-if="!fetching"
            type="button"
            value="Enviar"
            background="orangered"
            color="white"
            class="submit-button"
            @click="save"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;
}

.form {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 90%;
  max-width: 600px;
  background: #fff;
  padding: 2em;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  align-items: center; /* Para alinear verticalmente */
  justify-content: center; /* Para centrar horizontalmente */
}

.input {
  width: calc(50% - 8px);
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.submit-button {
  width: auto;
  padding: 0.50em 1em;
  text-align: center;
  background-color: orangered;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}
h3 {
  font-size: 1.5em;
  padding-top: 5em;
}

</style>
