<script setup>
import {reactive} from "vue";
import {useStore} from "vuex";

const form = reactive({});
const store = useStore();
const login = async () => {
  await store.dispatch("login", form);
}
</script>


<template>
  <body class="login-body">
    <section class="login__wrapper" title="Local La Fortaleza">
      <section class="login-section">
        <div class="form-box">
          <div class="form-value">
            <form @submit.prevent="login">
              <h2 class="h2-login">Iniciar sesión</h2>

              <div class="inputbox">
                <span>
                  <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#fff" stroke-width="4" width="65" height="65" viewBox="0 0 256 256">
      <g style="stroke-linecap: round; stroke-linejoin: round;">
        <path d="M 75.546 78.738 H 14.455 C 6.484 78.738 0 72.254 0 64.283 V 25.716 c 0 -7.97 6.485 -14.455 14.455 -14.455 h 61.091 c 7.97 0 14.454 6.485 14.454 14.455 v 38.567 C 90 72.254 83.516 78.738 75.546 78.738 z M 14.455 15.488 c -5.64 0 -10.228 4.588 -10.228 10.228 v 38.567 c 0 5.64 4.588 10.229 10.228 10.229 h 61.091 c 5.64 0 10.228 -4.589 10.228 -10.229 V 25.716 c 0 -5.64 -4.588 -10.228 -10.228 -10.228 H 14.455 z" />
        <path d="M 11.044 25.917 C 21.848 36.445 32.652 46.972 43.456 57.5 c 2.014 1.962 5.105 -1.122 3.088 -3.088 C 35.74 43.885 24.936 33.357 14.132 22.83 C 12.118 20.867 9.027 23.952 11.044 25.917 L 11.044 25.917 z" />
        <path d="M 46.544 57.5 c 10.804 -10.527 21.608 -21.055 32.412 -31.582 c 2.016 -1.965 -1.073 -5.051 -3.088 -3.088 C 65.064 33.357 54.26 43.885 43.456 54.412 C 41.44 56.377 44.529 59.463 46.544 57.5 L 46.544 57.5 z" />
        <path d="M 78.837 64.952 c -7.189 -6.818 -14.379 -13.635 -21.568 -20.453 c -2.039 -1.933 -5.132 1.149 -3.088 3.088 c 7.189 6.818 14.379 13.635 21.568 20.453 C 77.788 69.973 80.881 66.89 78.837 64.952 L 78.837 64.952 z" />
        <path d="M 14.446 68.039 c 7.189 -6.818 14.379 -13.635 21.568 -20.453 c 2.043 -1.938 -1.048 -5.022 -3.088 -3.088 c -7.189 6.818 -14.379 13.635 -21.568 20.453 C 9.315 66.889 12.406 69.974 14.446 68.039 L 14.446 68.039 z" />
      </g>
    </svg>
                </span>
                <input class="input-login" type="email" placeholder="" value="" required v-model="form.email" />
                <label class="label-login" for="email">Email</label>
              </div>

              <div class="inputbox">
                <span>
                  <svg class="svg-icon-2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0,0,256,256">
      <g fill="#ffffff" fill-rule="nonzero" stroke="#fff" stroke-width="3" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
        <g transform="scale(5.12,5.12)">
          <path d="M25,3c-6.63672,0 -12,5.36328 -12,12v5h-4c-1.64453,0 -3,1.35547 -3,3v24c0,1.64453 1.35547,3 3,3h32c1.64453,0 3,-1.35547 3,-3v-24c0,-1.64453 -1.35547,-3 -3,-3h-4v-5c0,-6.63672 -5.36328,-12 -12,-12zM25,5c5.56641,0 10,4.43359 10,10v5h-20v-5c0,-5.56641 4.43359,-10 10,-10zM9,22h32c0.55469,0 1,0.44531 1,1v24c0,0.55469 -0.44531,1 -1,1h-32c-0.55469,0 -1,-0.44531 -1,-1v-24c0,-0.55469 0.44531,-1 1,-1zM25,30c-1.69922,0 -3,1.30078 -3,3c0,0.89844 0.39844,1.6875 1,2.1875v2.8125c0,1.10156 0.89844,2 2,2c1.10156,0 2,-0.89844 2,-2v-2.8125c0.60156,-0.5 1,-1.28906 1,-2.1875c0,-1.69922 -1.30078,-3 -3,-3z"></path>
        </g>
      </g>
    </svg>
                </span>
                <input class="input-login" type="password" placeholder="" value="" required v-model="form.password" />
                <label class="label-login" for="password">Contraseña</label>
              </div>

              <button class="login-btn">Iniciar sesión</button>

              <div class="register__link">
                <p>
                  ¿No tienes una cuenta?
                  <a href="/signup">Crea una cuenta</a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </section>
    </section>
  </body>
</template>


<style scoped>
/* Generales */
body {
    margin: 0;
    padding: 0;
}

/* Login Wrapper */
.login__wrapper {
    margin: 0;
    padding: 0;
    background: url(../../public/images/local-fortaleza2.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

/* Login Section */
.login-section {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    align-items: center;
}

/* Form Box */
.form-box {
    position: relative;
    width: 400px;
    height: 500px;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    backdrop-filter: blur(15px);
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Título */
.h2-login {
    font-size: 2em;
    color: #fff;
    text-align: center;
    width: 100%;
}

/* Input Box */
.inputbox {
    position: relative;
    margin: 30px 0;
    width: 310px;
    border-bottom: 2px solid #fff;
}

/* Etiqueta del input */
.inputbox .label-login {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    color: #fff;
    font-size: 1em;
    pointer-events: none;
    transition: .5s;
}

/* Input Focus/Validado */
.input-login:focus ~ .label-login, 
.input-login:valid ~ .label-login {
    top: -5px;
}

/* Estilo del input */
.inputbox .input-login {
    width: 100%;
    height: 50px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 1em;
    padding: 35px 0 5px;
    color: #fff;
}

/* Icono dentro del input */
.inputbox i {
    position: absolute;
    right: 8px;
    color: #fff;
    font-size: 1.2em;
    top: 20px;
}

/* Botón de login */
.login-btn {
    margin: 10px 0;
    width: 100%;
    height: 40px;
    border-radius: 40px;
    background: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1em;
    font-weight: 600;
}

.login-btn:hover {
    background: #F8853E;
    color: #fff;
    transition: 500ms ease;
    transform: scale(1.1);
}

/* Enlace de registro */
.register__link {
    font-size: .9em;
    color: #000;
    text-align: center;
    margin: 25px 0 10px;
}

.register__link p {
    color: #fff;
}

.register__link p a {
    color: #fff;
    text-decoration: none;
    font-weight: 600;
}

.register__link p a:hover {
    text-decoration: underline;
}

/* Estilos para los iconos SVG */
.svg-icon, .svg-icon-2 {
    position: absolute;
    right: 0;
    z-index: 10;
}

.svg-icon {
    right: -40px;
    top: 90%;
    transform: translateY(-50%);
}

.svg-icon-2 {
    top: 65%;
    transform: translateY(-90%);
}

/* Medios: Estilos Responsivos */
@media screen and (max-width: 420px) {
    .login__wrapper {
        padding: 40px 0;
    }

    .form-box {
        width: 95%;
        max-width: 100%;
    }

    .h2-login {
        font-size: 1.6em;
    }

    .inputbox {
        width: 100%;
    }

    .login-btn {
        font-size: 0.9em;
    }
}
</style>
