<template>
  <div class="info-page">
    <h1>{{ info?.title }}</h1>
    <div v-html="info?.content"></div>
  </div>
</template>

<script setup>

import {useRoute, useRouter} from "vue-router";
import {onMounted, ref} from "vue";

const route = useRoute();

const {tab} = route.params;

const info = ref({});
const tabInfo = {
  comoCompro: {
    title: '¿Como Compro?',
    content: `
        <li>Para poder hacer una compra es necesario tener una cuenta.</li>
        <br>
        <li>Podrá añadir los productos que quiera, luego los encontrará en el carrito.</li>
        <br>
        <li>Una vez terminada, podrá encontrar su orden de compra en el apartado MIS COMPRAS.</li>
        <br>
        <li>Al principio figurará con estado PENDIENTE, esto es hasta que el local confirme su orden.</li>
        <br>
        <li>Una vez confirmada, la orden cambiará a estado CONFIRMADO, en el cual podrá comunicarse con el local para poder abonar.</li>
    `
  },
  comoPago: {
    title: '¿Como Pago?',
    content: `
        <li>Podrá realizar el pago en efectivo.</li>
        <br>
        <li>También aceptamos tarjetas de débito (excepto Cabal).</li>
        <br>
        <li>Tarjeta de crédito.</li>
        <br>
        <li>Transferencias bancarias y Mercado Pago.</li>
        <br>
        <li>Transferencia con Cuenta DNI.</li>
        <li>Recuerde pedir su QR o link de pago para obtener los reintegros que ofrece el banco.</li>
        <br>
        <li>Reintegro TODOS LOS DÍAS.</li>
        <li>-20% con tope de $6.000 por mes y por persona.</li>
    `
  },
  comoRecibo: {
    title: '¿Como Recibo?',
    content: `
        <li>Realizamos envíos en moto de lunes a sábados.</li>
        <br>
        <li>¡No hay compra mínima!</li>
        <br>
        <li>Solo tratamos de calcular una caja del tamaño apropiado para que una moto pueda llevarla.
        También se pueden llevar hasta 2 bolsones de harina de 25 kg.</li>
        <br>
        <li>Si le interesa saber cuánto cuesta el envío hasta su domicilio, solo envíenos su dirección escrita y ubicación exacta, y con los kilómetros de distancia se lo calculamos.</li>
        <br>
        <li>Hasta 3 km, el envío cuesta $3.000.</li>
        <br>
        <li>¡Envíos por Florencio Varela y alrededores!</li>
        <br>
        <li>Al ser un envío externo, la compra debe ser abonada por transferencia de Mercado Pago o por link de pago con Cuenta DNI (con reintegros).</li>
        <br>
        <li>Si necesita hacer una compra más grande, tenemos la opción de envío en camioneta. En esta opción, puede llevar la cantidad que quiera. 
        Realizamos envíos de lunes a viernes.</li>
        <br>
        <li>Si desea saber cuál es el monto mínimo de compra para que el envío sea GRATIS, pásenos su ubicación exacta y, con los kilómetros, se lo calculamos. 
        Por el momento, hacemos envíos en camioneta ÚNICAMENTE en Florencio Varela.</li>
        <br>
        <li>Al ser un envío propio, puede abonarlo como prefiera.</li>
        <br>
        <li>Efectivo, transferencia de Mercado Pago o link de pago con Cuenta DNI (con reintegros).</li>
    `
  },
};

onMounted(() => {
  info.value = tabInfo[tab];
})

</script>

<style scoped>
.info-page {
  padding: 20px;
}

h2 {
  font-size: 24px;
  color: #333;
}

p {
  font-size: 16px;
  color: #666;
}

.info-page {
  min-height: 500px;
}

.list {
  list-style: disc !important;
}
</style>
