import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import BuyOnline from "@/views/BuyOnline.vue";
import ContactView from "@/views/ContactView.vue";
import Login from "@/views/Login.vue";
import Signup from "@/views/Signup.vue";
import ItemDetail from "@/views/ItemDetail.vue";
import CartView from "@/views/CartView.vue";
import Shopping from "@/views/Shopping.vue";
import Checkout from "@/views/Checkout.vue";
import Shipment from "@/views/Shipment.vue";
import Ticket from "@/views/Ticket.vue";
import BuyOnlineEspecial from "@/views/BuyOnlineEspecial.vue";
import FormularioSorteo from "@/views/FormularioSorteo.vue";
import TableSorteo from "@/views/TableSorteo.vue";
import TableSorteo2 from "@/views/TableSorteo2.vue";
import FormularioSorteo2 from "@/views/FormularioSorteo2.vue";
import Comprobante from "@/views/Sorteo/Comprobante.vue";
import BargainStore from "@/views/BargainStore.vue";
import NewBuyOnline from "@/views/NewBuyOnline.vue";
import InfoSection from "@/components/InfoSection.vue";
import DescriptionInfoSection from "@/views/DescriptionInfoSection.vue";
import TableContacts from "@/views/TableContacts.vue";
import GenQR from "@/views/GenQR.vue";
import WorkWithUs from "@/views/WorkWithUs.vue";

const routes = [
  {
    path: "/",
    name: "Inicio",
    component: HomeView,
  },
  {
    path: "/tienda",
    name: "Tienda",
    component: BuyOnline,
  },
  {
    path: "/especial",
    name: "Especial",
    component: BuyOnlineEspecial,
  },
  {
    path: "/nosotros",
    name: "Nosotros",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/contacto",
    name: "Contacto",
    component: ContactView,
  },
  {
    path: "/login",
    name: "Iniciar Sesion",
    component: Login,
  },
  {
    path: "/signup",
    name: "Registrarse",
    component: Signup,
  },
  {
    path: "/itemdetail",
    name: "Producto",
    component: ItemDetail,
  },
  {
    path: "/carrito",
    name: "Mi carrito",
    component: CartView,
  },
  {
    path: "/compras",
    name: "Compras",
    component: Shopping,
  },
  {
    path: "/verificacion",
    name: "Checkout",
    component: Checkout,
  },
  {
    path: "/verificacion/envio",
    name: "Envio",
    component: Shipment,
  },
  {
    path: "/verificacion/envio/ticket",
    name: "Ticket",
    component: Ticket,
  },
  {
    path: "/formulario/sorteo",
    name: "FSorteo",
    component: FormularioSorteo,
  },
  {
    path: "/formulario/table",
    name: "TableSorteo",
    component: TableSorteo,
  },
  {
    path: "/formulario/sorteo2",
    name: "FSorteo2",
    component: FormularioSorteo2,
  },
  {
    path: "/formulario/table2",
    name: "TableSorteo2",
    component: TableSorteo2,
  },
  {
    path: "/formulario/comprobante",
    name: "comprobante",
    component: Comprobante,
  },
  {
    path: "/ofertas",
    name: "ofertas",
    component: BargainStore,
  },
  {
    path: "/newtienda",
    name: "Tienda",
    component: NewBuyOnline,
  },
  {
    path: "/infoSection/:tab",
    name: "info",
    component: DescriptionInfoSection,
  },
  {
    path: "/qr",
    name: "QR",
    component: GenQR,
  },
  {
    path: "/userquestions",
    name: "userquestions",
    component: TableContacts,
  },
  {
    path: "/workwithus",
    name: "workwithus",
    component: WorkWithUs,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `La Fortaleza - ${to?.name}`;
  next();
});

export default router;
