<script setup>
import {useStore} from "vuex";
import {reactive, ref} from "vue";
import {show} from "../../services/notification";

const form = reactive({});

const Show = ref(true);

const store = useStore();
const register = async () => {
  if (form.document.toString()?.length < 8 || form.phone.toString()?.length < 8) {
    show('Documento o telefono invalido');
    return;
  }
  await store.dispatch("register", form);
  Show.value = true;
}
</script>


<template>
  <body>
  <section class='signup__wrapper' title="Local La Fortaleza">
    <section class='login-section'>
      <div class="form-box">
        <div class="form-value">
          <form @submit.prevent="register">
            <h2 class='h2-login'>Registrarse</h2>

            <div class="inputbox">
                    <span>
                      <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="20" height="20" viewBox="0 0 256 256" xml:space="preserve">
  <g style="stroke: white; stroke-width: 3; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #fff; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
    <path d="M 85.347 90 c 0 -22.283 -18.064 -40.347 -40.347 -40.347 S 4.653 67.717 4.653 90 H 85.347 z" style="stroke: white; stroke-width: 3; fill: #fff; opacity: 1;" transform=" matrix(1 0 0 1 0 0)" />
    <circle cx="45.003" cy="21.413" r="21.413" style="stroke: white; stroke-width: 3; fill: #fff; opacity: 1;" transform=" matrix(1 0 0 1 0 0)" />
  </g>
</svg>

                    </span>
              <input class='input-login' type="text" value='' required v-model="form.name"/>
              <label class='label-login' htmlFor="fullname">Nombre y apellido</label>
            </div>

            <div class="inputbox">
                    <span>
                      <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="20" height="20" viewBox="0 0 256 256" xml:space="preserve">
  <g style="stroke: white; stroke-width: 6; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #ffffff; fill-rule: nonzero;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
    <path d="M 82.592 76.137 H 7.408 C 3.323 76.137 0 72.813 0 68.729 V 21.272 c 0 -4.085 3.323 -7.408 7.408 -7.408 h 75.184 c 4.085 0 7.408 3.323 7.408 7.408 v 47.457 C 90 72.813 86.677 76.137 82.592 76.137 z M 7.408 15.864 C 4.426 15.864 2 18.29 2 21.272 v 47.457 c 0 2.982 2.426 5.408 5.408 5.408 h 75.184 c 2.982 0 5.408 -2.426 5.408 -5.408 V 21.272 c 0 -2.982 -2.426 -5.408 -5.408 -5.408 H 7.408 z" style="stroke: none; stroke-width: 6; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #ffffff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
    <path d="M 21.719 41.216 c -3.553 0 -6.443 -2.891 -6.443 -6.444 V 32.34 c 0 -3.553 2.891 -6.444 6.443 -6.444 c 3.553 0 6.444 2.891 6.444 6.444 v 2.432 C 28.163 38.326 25.272 41.216 21.719 41.216 z M 21.719 27.896 c -2.45 0 -4.443 1.994 -4.443 4.444 v 2.432 c 0 2.45 1.993 4.444 4.443 4.444 s 4.444 -1.994 4.444 -4.444 V 32.34 C 26.163 29.89 24.169 27.896 21.719 27.896 z" style="stroke: none; stroke-width: 6; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #ffffff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
    <path d="M 28.762 54.336 H 14.677 c -1.701 0 -3.084 -1.384 -3.084 -3.084 v -3.867 c 0 -4.11 2.456 -7.783 6.258 -9.357 l 0.559 -0.231 l 0.464 0.388 c 1.596 1.332 4.097 1.331 5.692 0 l 0.464 -0.388 l 0.559 0.231 c 3.802 1.574 6.258 5.247 6.258 9.357 v 3.867 C 31.846 52.952 30.463 54.336 28.762 54.336 z M 18.106 40.106 c -2.759 1.368 -4.514 4.167 -4.514 7.278 v 3.867 c 0 0.598 0.486 1.084 1.084 1.084 h 14.085 c 0.598 0 1.084 -0.486 1.084 -1.084 v -3.867 c 0 -3.111 -1.754 -5.91 -4.514 -7.278 C 23.208 41.545 20.232 41.545 18.106 40.106 z" style="stroke: none; stroke-width: 6; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #ffffff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
    <path d="M 77.188 64.644 H 12.813 c -0.552 0 -1 -0.447 -1 -1 s 0.448 -1 1 -1 h 64.375 c 0.553 0 1 0.447 1 1 S 77.74 64.644 77.188 64.644 z" style="stroke: none; stroke-width: 6; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #ffffff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
    <path d="M 77.188 52.395 H 45 c -0.552 0 -1 -0.447 -1 -1 s 0.448 -1 1 -1 h 32.188 c 0.553 0 1 0.447 1 1 S 77.74 52.395 77.188 52.395 z" style="stroke: none; stroke-width: 6; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #ffffff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
    <path d="M 77.188 27.896 H 59.561 c -0.553 0 -1 -0.448 -1 -1 s 0.447 -1 1 -1 h 17.627 c 0.553 0 1 0.448 1 1 S 77.74 27.896 77.188 27.896 z" style="stroke: none; stroke-width: 6; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #ffffff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
    <path d="M 51.133 27.896 H 45 c -0.552 0 -1 -0.448 -1 -1 s 0.448 -1 1 -1 h 6.133 c 0.553 0 1 0.448 1 1 S 51.686 27.896 51.133 27.896 z" style="stroke: none; stroke-width: 6; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #ffffff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
    <path d="M 77.188 40.146 H 59.561 c -0.553 0 -1 -0.448 -1 -1 s 0.447 -1 1 -1 h 17.627 c 0.553 0 1 0.448 1 1 S 77.74 40.146 77.188 40.146 z" style="stroke: none; stroke-width: 6; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #ffffff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
    <path d="M 51.133 40.146 H 45 c -0.552 0 -1 -0.448 -1 -1 s 0.448 -1 1 -1 h 6.133 c 0.553 0 1 0.448 1 1 S 51.686 40.146 51.133 40.146 z" style="stroke: none; stroke-width: 6; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #ffffff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
  </g>
</svg>


                    </span>
              <input class='input-login' type="number" placeholder='' value='' required v-model="form.document"/>
              <label class='label-login' htmlFor="cuil">CUIL / DNI</label>
            </div>

            <div class="inputbox">
                    <span>
                      <svg
                      class="svg-icon" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlns:xlink="http://www.w3.org/1999/xlink" 
    version="1.1" 
    width="20" 
    height="20" 
    viewBox="0 0 256 256"  
    stroke="white"
>
  <g 
      style="stroke: white; stroke-width: 3; stroke-linecap: round; stroke-linejoin: round; fill: white; opacity: 1;" 
      transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
  >
    <path 
        stroke="white" 
        d="M 69.315 90.026 C 55.681 90.028 38.46 79.081 24.689 65.311 C 6.015 46.638 -7.468 21.613 4.506 7.8 C 5.267 6.922 6.24 6.248 7.397 5.794 c 1.428 -0.56 4.015 -1.651 7.687 -3.244 l 0.008 -0.003 c 1.707 -0.74 3.346 -1.458 4.599 -2.009 c 2.946 -1.292 6.401 -0.181 8.045 2.587 l 10.407 17.539 c 1.134 1.91 1.186 4.313 0.135 6.271 L 32.043 38.55 L 51.45 57.957 l 11.613 -6.235 c 1.962 -1.052 4.364 -0.998 6.272 0.135 l 16.293 9.668 l 2.157 1.395 l 0 0 c 2.086 1.799 2.815 4.794 1.676 7.39 c -0.469 1.064 -1.057 2.406 -1.676 3.831 l 0.756 -1.735 l -1.127 2.589 c -1.548 3.571 -2.657 6.2 -3.21 7.61 c -0.453 1.156 -1.128 2.129 -2.004 2.89 C 78.574 88.638 74.171 90.026 69.315 90.026 z M 17.98 8.925 L 17.98 8.925 c -3.807 1.651 -6.508 2.791 -8.028 3.387 C 9.828 12.36 9.781 12.4 9.781 12.4 c -8.562 9.878 3.505 31.608 19.858 47.961 c 16.353 16.352 38.079 28.418 47.974 19.846 h -0.001 c -0.002 0 0.033 -0.049 0.076 -0.157 c 0.602 -1.534 1.741 -4.235 3.388 -8.031 l 1.745 -4.021 l -16.755 -9.942 l -11.711 6.287 c -2.5 1.341 -5.524 0.893 -7.527 -1.11 l -20.06 -20.06 c -2.004 -2.005 -2.451 -5.03 -1.11 -7.527 l 6.287 -11.711 L 21.997 7.171 C 20.843 7.678 19.438 8.292 17.98 8.925 z"
    />
  </g>
</svg>

                    </span>
                    <input class='input-login' type='number' placeholder='' value='' required v-model="form.phone"/>
                    <label class='label-login' htmlFor="cuil">Teléfono</label>
                  </div>

            <div class="inputbox">
                    <span>
                      <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="20" height="20" viewBox="0 0 256 256" xml:space="preserve">
  <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
    <path d="M 75.546 78.738 H 14.455 C 6.484 78.738 0 72.254 0 64.283 V 25.716 c 0 -7.97 6.485 -14.455 14.455 -14.455 h 61.091 c 7.97 0 14.454 6.485 14.454 14.455 v 38.567 C 90 72.254 83.516 78.738 75.546 78.738 z M 14.455 15.488 c -5.64 0 -10.228 4.588 -10.228 10.228 v 38.567 c 0 5.64 4.588 10.229 10.228 10.229 h 61.091 c 5.64 0 10.228 -4.589 10.228 -10.229 V 25.716 c 0 -5.64 -4.588 -10.228 -10.228 -10.228 H 14.455 z" style="stroke: none; stroke-width: 3; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: white; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
    <path d="M 11.044 25.917 C 21.848 36.445 32.652 46.972 43.456 57.5 c 2.014 1.962 5.105 -1.122 3.088 -3.088 C 35.74 43.885 24.936 33.357 14.132 22.83 C 12.118 20.867 9.027 23.952 11.044 25.917 L 11.044 25.917 z" style="stroke: none; stroke-width: 3; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: white; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
    <path d="M 46.544 57.5 c 10.804 -10.527 21.608 -21.055 32.412 -31.582 c 2.016 -1.965 -1.073 -5.051 -3.088 -3.088 C 65.064 33.357 54.26 43.885 43.456 54.412 C 41.44 56.377 44.529 59.463 46.544 57.5 L 46.544 57.5 z" style="stroke: none; stroke-width: 3; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: white; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
    <path d="M 78.837 64.952 c -7.189 -6.818 -14.379 -13.635 -21.568 -20.453 c -2.039 -1.933 -5.132 1.149 -3.088 3.088 c 7.189 6.818 14.379 13.635 21.568 20.453 C 77.788 69.973 80.881 66.89 78.837 64.952 L 78.837 64.952 z" style="stroke: none; stroke-width: 3; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: white; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
    <path d="M 14.446 68.039 c 7.189 -6.818 14.379 -13.635 21.568 -20.453 c 2.043 -1.938 -1.048 -5.022 -3.088 -3.088 c -7.189 6.818 -14.379 13.635 -21.568 20.453 C 9.315 66.889 12.406 69.974 14.446 68.039 L 14.446 68.039 z" style="stroke: none; stroke-width: 3; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: white; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
  </g>
</svg>


                    </span>
              <input class='input-login' type="email" placeholder='' value='' required v-model="form.email"/>
              <label class='label-login' htmlFor="email">Email</label>
            </div>

            <div class="inputbox">
                    <span>
                      <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="20" height="20" viewBox="0 0 256 256" xml:space="preserve">
<g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
	<path d="M 69.629 90 H 20.371 c -4.524 0 -8.205 -3.681 -8.205 -8.205 V 41.899 c 0 -4.524 3.681 -8.205 8.205 -8.205 h 49.258 c 4.524 0 8.205 3.681 8.205 8.205 v 39.896 C 77.834 86.319 74.153 90 69.629 90 z M 20.371 37.694 c -2.319 0 -4.205 1.886 -4.205 4.205 v 39.896 c 0 2.318 1.886 4.205 4.205 4.205 h 49.258 c 2.318 0 4.205 -1.887 4.205 -4.205 V 41.899 c 0 -2.319 -1.887 -4.205 -4.205 -4.205 H 20.371 z" style="stroke: white; stroke-width: 2; stroke-dasharray: none; stroke-linecap: round; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
	<path d="M 45 76.483 c -2.972 0 -5.39 -2.418 -5.39 -5.391 v -8.161 c -2.235 -1.946 -3.253 -4.952 -2.616 -7.945 c 0.656 -3.086 3.172 -5.593 6.26 -6.238 c 2.468 -0.512 4.986 0.086 6.913 1.651 c 1.925 1.564 3.029 3.884 3.029 6.362 c 0 2.389 -1.031 4.623 -2.805 6.17 v 8.161 C 50.391 74.065 47.973 76.483 45 76.483 z M 45.005 52.565 c -0.307 0 -0.619 0.032 -0.933 0.099 c -1.562 0.326 -2.834 1.594 -3.166 3.154 c -0.371 1.744 0.336 3.492 1.801 4.452 c 0.563 0.37 0.903 0.999 0.903 1.673 v 9.149 c 0 0.767 0.624 1.391 1.39 1.391 s 1.391 -0.624 1.391 -1.391 v -9.149 c 0 -0.674 0.34 -1.303 0.903 -1.673 c 1.19 -0.78 1.901 -2.092 1.901 -3.509 c 0 -1.269 -0.565 -2.456 -1.553 -3.258 C 46.884 52.887 45.97 52.565 45.005 52.565 z" style="stroke: white; stroke-width: 2; stroke-dasharray: none; stroke-linecap: round; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
	<path d="M 65.985 37.694 c -1.104 0 -2 -0.896 -2 -2 V 21.259 C 63.985 11.743 56.242 4 46.726 4 h -3.451 c -9.517 0 -17.259 7.743 -17.259 17.259 v 14.435 c 0 1.104 -0.896 2 -2 2 s -2 -0.896 -2 -2 V 21.259 C 22.015 9.537 31.552 0 43.274 0 h 3.451 c 11.723 0 21.26 9.537 21.26 21.259 v 14.435 C 67.985 36.799 67.09 37.694 65.985 37.694 z" style="stroke: white; stroke-width: 2; stroke-dasharray: none; stroke-linecap: round; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
	<path d="M 58.348 37.694 c -1.104 0 -2 -0.896 -2 -2 V 22.068 c 0 -5.751 -4.68 -10.431 -10.431 -10.431 c -6.762 0 -12.264 5.501 -12.264 12.264 v 11.792 c 0 1.104 -0.896 2 -2 2 s -2 -0.896 -2 -2 V 23.902 c 0 -8.968 7.296 -16.264 16.264 -16.264 c 7.958 0 14.431 6.474 14.431 14.431 v 13.626 C 60.348 36.799 59.452 37.694 58.348 37.694 z" style="stroke: white; stroke-width: 2; stroke-dasharray: none; stroke-linecap: round; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
</g>
</svg>

                    </span>
              <input class='input-login' type="password" placeholder='' value='' required v-model="form.password"/>
              <label class='label-login' htmlFor="password">Contraseña</label>
            </div>

            <button type="submit" class='login-btn' v-if="show">Crear cuenta</button>
            <div class="register__link">
              <p>
                ¿Ya tienes una cuenta?
                <a href="/login">Iniciar sesión</a>
              </p>
            </div>

          </form>
        </div>
      </div>
    </section>
  </section>
  </body>
</template>


<style scoped>
.signup__wrapper {
  padding: 0;
  background: url(../../public/images/local-fortaleza2.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-section {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  min-width: 100%;
  align-items: center;
}

.form-box {
  position: relative;
  width: 400px;
  height: 680px;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  backdrop-filter: blur(15px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.h2-login {
  font-size: 2em;
  color: #fff;
  margin-top: 25px;
  width: 100%;
  text-align: center;
  justify-content: center;
}

.inputbox {
  position: relative;
  margin: 30px 0;
  width: 310px;
  border-bottom: 2px solid #fff;
}

.inputbox .label-login {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    color: #fff;
    font-size: 1em;
    pointer-events: none;
    transition: .5s;
}

.input-login:focus ~ .label-login,
.input-login:valid ~ .label-login {
  top: -5px;
}

.inputbox .input-login {
  width: 100%;
  height: 50px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 1em;
  padding: 35px 0 5px;
  color: #fff;
}

.inputbox i {
  position: absolute;
  right: 8px;
  color: #fff;
  font-size: 1.2em;
  top: 20px;
}

.forget {
  margin: -5px 0 15px;
  font-size: .9em;
  color: #fff;
  display: flex;
  justify-content: center;
}

.forget .label-login .input-login {
  margin-right: 3px;
}

.forget .input-login {
  color: #fff;
}

.login-btn {
  margin: 10px 0;
  width: 100%;
  height: 40px;
  border-radius: 40px;
  background: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1em;
  font-weight: 600;
}

.login-btn:hover {
  background: #F8853E;
  color: #fff;
  transition: 500ms ease;
  transform: scale(1.1);
}

.register__link {
  font-size: .9em;
  color: #000;
  text-align: center;
  margin: 25px 0 10px;
}

.register__link p {
  color: #fff;
}

.register__link p a {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
}

.register__link p a:hover {
  text-decoration: underline;
}

/* Estilos para los iconos SVG */
.svg-icon, .svg-icon-2 {
    position: absolute;
    right: 0;
    z-index: 10;
}

.svg-icon {
    color: #fff !important;
    stroke: #fff !important;
    stroke-width: 3 !important;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
}

@media only screen and (max-width: 768px) {
  .signup__wrapper {
    padding: 110px 0;
  }

  .form-box {
    width: 95%;
    max-width: 100%;
  }

  .h2-login {
    font-size: 1.6em;
  }

  .inputbox {
    width: 100%;
  }

  .login-btn {
    font-size: 0.9em;
  }
}
</style>
