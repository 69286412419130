<script setup>
import {onMounted, ref} from "vue";
import {DBService, post} from "../../services/apiReq2";
import {formatNumber, getDate} from "../../src/utils/utils"

const data = ref([])
onMounted(async () => {
  let response = await post("/read",{
    schema: "Fortaleza.db",
    table:"formularios_sorteo2",
    limit: 999,
    page: 1,
  }, DBService);
  data.value = response.data.rows;
});

</script>

<template>
  <div class="table-container">
    <div class="table-wrapper">
      <table class="data-table">
        <thead>
        <tr>
          <th>#</th>
          <th>Nombre</th>
          <th>Apellido</th>
          <th>Email</th>
          <th>Telefono</th>
          <th>DNI</th>
          <th>Fecha</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in data" :key="index">
          <td>{{ formatNumber(item.id) }}</td>
          <td>{{ item.nombre }}</td>
          <td>{{ item.apellido }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.telefono }}</td>
          <td>{{ item.dni }}</td>
          <td>{{ item.date }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>
.table-container {
  width: 90%;
  max-width: 100%;
  margin: 5em auto;
  overflow-x: auto;
}

.table-wrapper {
  max-height: 700px;
  overflow-y: auto;
  border: 1px solid #ddd;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  background: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.data-table thead {
  position: sticky;
  top: 0;
  background-color: #f4f4f4;
  z-index: 2;
}

.data-table th,
.data-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.data-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.data-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.data-table tr:hover {
  background-color: #f1f1f1;
}
</style>
