<script setup>
  const year = new Date().getFullYear();
</script>

<template>
    <footer class='footer'>
      <div class="footer-container">
        <div class="footer-row">

          <div class="footer-links">
            <h4>Links</h4>
            <ul class="links-site">
              <li><a href="/">Inicio</a></li>
              <li><a href="/newtienda">Tienda</a></li>
              <li><a href="/nosotros">Nosotros</a></li>
              <li><a href="/contacto">Contacto</a></li>
              <li><a href="/carrito">Carrito</a></li>
            </ul>
          </div>

          <div class="footer-links">
            <h4>Contáctanos</h4>
            <div class='social-link'>
              <a href="https://www.instagram.com/distribuidora_lfortaleza/" target='_blank' title="Instagram La Fortaleza">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="20" height="20" viewBox="0 0 256 256" xml:space="preserve">
  <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
    <path d="M 62.263 90 H 27.738 C 12.443 90 0 77.557 0 62.263 V 27.738 C 0 12.443 12.443 0 27.738 0 h 34.525 C 77.557 0 90 12.443 90 27.738 v 34.525 C 90 77.557 77.557 90 62.263 90 z M 27.738 7 C 16.303 7 7 16.303 7 27.738 v 34.525 C 7 73.697 16.303 83 27.738 83 h 34.525 C 73.697 83 83 73.697 83 62.263 V 27.738 C 83 16.303 73.697 7 62.263 7 H 27.738 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #ffffff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
    <path d="M 45.255 70.47 c -13.904 0 -25.215 -11.312 -25.215 -25.215 S 31.352 20.04 45.255 20.04 s 25.215 11.312 25.215 25.215 S 59.159 70.47 45.255 70.47 z M 45.255 27.04 c -10.044 0 -18.215 8.171 -18.215 18.215 c 0 10.044 8.171 18.215 18.215 18.215 c 10.044 0 18.215 -8.171 18.215 -18.215 C 63.471 35.211 55.3 27.04 45.255 27.04 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #ffffff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
    <circle cx="70.557" cy="19.936999999999998" r="4.897" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #ffffff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) "/>
  </g>
</svg>

              </a>
              <a href="https://www.facebook.com/Dist.LFortaleza/" target='_blank' title=" Facebook La Fortaleza">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="20" height="20" viewBox="0 0 256 256" xml:space="preserve">
  <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
    <path d="M 51.991 90 V 49.008 h 13.781 l 2.12 -16.049 H 51.991 V 22.739 c 0 -4.632 1.293 -7.791 7.94 -7.791 h 8.417 V 0.637 C 64.25 0.196 60.13 -0.017 56.009 0.001 c -12.212 0 -20.576 7.42 -20.576 21.148 v 11.809 H 21.652 v 16.049 h 13.781 V 90 H 51.991 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #ffffff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
  </g>
</svg>

              </a>
              <a href="https://www.whatsapp.com/catalog/5491128347514/?app_absent=0" target='_blank' title="Whatsapp La Fortaleza">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="20" height="20" viewBox="0 0 256 256" xml:space="preserve">
  <g style="stroke: white; stroke-width: 3; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #fff; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
    <path d="M 0.576 44.596 C 0.573 52.456 2.626 60.129 6.53 66.892 L 0.201 90 l 23.65 -6.201 c 6.516 3.553 13.852 5.426 21.318 5.429 h 0.019 c 24.586 0 44.601 -20.009 44.612 -44.597 c 0.004 -11.917 -4.633 -23.122 -13.055 -31.552 C 68.321 4.65 57.121 0.005 45.188 0 C 20.597 0 0.585 20.005 0.575 44.595 M 14.658 65.727 l -0.883 -1.402 c -3.712 -5.902 -5.671 -12.723 -5.669 -19.726 C 8.115 24.161 24.748 7.532 45.201 7.532 c 9.905 0.004 19.213 3.865 26.215 10.871 c 7.001 7.006 10.854 16.32 10.851 26.224 c -0.009 20.439 -16.643 37.068 -37.08 37.068 h -0.015 c -6.655 -0.004 -13.181 -1.79 -18.872 -5.168 l -1.355 -0.803 l -14.035 3.68 L 14.658 65.727 z M 45.188 89.228 L 45.188 89.228 L 45.188 89.228 C 45.187 89.228 45.187 89.228 45.188 89.228" style="stroke: white; stroke-width: 3; fill: #fff; opacity: 1;" transform=" matrix(1 0 0 1 0 0)" />
    <path d="M 34.038 25.95 c -0.835 -1.856 -1.714 -1.894 -2.508 -1.926 c -0.65 -0.028 -1.394 -0.026 -2.136 -0.026 c -0.744 0 -1.951 0.279 -2.972 1.394 c -1.022 1.116 -3.902 3.812 -3.902 9.296 c 0 5.485 3.995 10.784 4.551 11.529 c 0.558 0.743 7.712 12.357 19.041 16.825 c 9.416 3.713 11.333 2.975 13.376 2.789 c 2.044 -0.186 6.595 -2.696 7.524 -5.299 c 0.929 -2.603 0.929 -4.834 0.651 -5.299 c -0.279 -0.465 -1.022 -0.744 -2.137 -1.301 c -1.115 -0.558 -6.595 -3.254 -7.617 -3.626 c -1.022 -0.372 -1.765 -0.557 -2.509 0.559 c -0.743 1.115 -2.878 3.625 -3.528 4.368 c -0.65 0.745 -1.301 0.838 -2.415 0.28 c -1.115 -0.559 -4.705 -1.735 -8.964 -5.532 c -3.314 -2.955 -5.551 -6.603 -6.201 -7.719 c -0.65 -1.115 -0.069 -1.718 0.489 -2.274 c 0.501 -0.499 1.115 -1.301 1.673 -1.952 c 0.556 -0.651 0.742 -1.116 1.113 -1.859 c 0.372 -0.744 0.186 -1.395 -0.093 -1.953 C 37.195 33.666 35.029 28.154 34.038 25.95" style="stroke: white; stroke-width: 3; fill: #fff; opacity: 1;" transform=" matrix(1 0 0 1 0 0)" />
  </g>
</svg>

              </a>
            </div>
          </div>

          <div class="footer-links">
            <h4>Dirección</h4>
            <div>
              <iframe class="maps" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d818.85919396067!2d-58.277266525138955!3d-34.82010927046223!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a3298071ff877b%3A0x8129629e64d2c845!2sDISTRIBUIDORA%20LA%20FORTALEZA!5e0!3m2!1ses!2sar!4v1713316228726!5m2!1ses!2sar" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="Dirección La Fortaleza"></iframe>
            </div>
          </div>

          <div class="footer-links">
            <h4>Creadores</h4>
            <div class="devs-container">
              <p>&copy; {{year}} developed by <br>
                <a class="creators-link" href="https://corpresi.com/" target="_blank" rel="noopener norreferrer">Resi Corporation</a>. All rights reserved.
              </p>
            </div>
          </div>
          
        </div>
      </div>
    </footer>
</template>


<style scoped>
.footer-container {
    max-width: 1200px;
    margin: 0 auto;
}

.footer {
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    padding: 80px 0;
}

.footer-row {
    display: flex;
    flex-wrap: wrap;
}
   
.footer-links {
    width: 25%;
    padding: 0 15px; 
}

.footer-links h4 {
    font-size: 20px;
    color: #000;
    margin-bottom: 25px;
    font-weight: 500;
    border-bottom: 2px solid #ff731c;
    padding-bottom: 10px;
    display: inline-block;
}

.links-site {
  padding: 0;
}

.footer-links ul li a {
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    color: #2b2b2b;
    display: block;
    margin-bottom: 15px;
    transition: all .3s ease;
}

.footer-links ul li a:hover {
    color: #ff731c;
    padding-left: 6px;
}

.social-link a {
    display: inline-block;
    min-height: 40px;
    width: 40px;
    background-color: #515151;
    margin: 0 10px 10px 0;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color: #fff;
    transition: all .5s ease;
} 

.social-link a:hover {
    background-color: #ff731c;
}

ion-icon {
    position: relative;
    top: 5px !important;
    font-size: 24px;
}

.maps {
    opacity: .4;
    border: 2px double black;
    width: 280px; 
    height:250px; 
}

.maps:hover {
    opacity: 1;
    border: 2px double #ff6200;
}

.devs-container {
  padding-left: 10px;
}

.footer__copyright {
    padding: 0px 10px;
    color: #808080;
    font-weight: 600;
    text-align: center;
}

.creators-link {
  color: #4d4d4d;
  transition: all 0.3s ease;
  font-weight: 600;
}

.creators-link:hover {
  color: #000; 
  text-decoration: underline;
  text-shadow: 0 0 10px rgba(231, 231, 231, 0.8), 0 0 20px rgba(202, 202, 202, 0.6); 
  transition: all 0.3s ease;
}


@media only screen and (max-width: 991px) {
    .footer-row {
        text-align: center;
    }

    .footer-links {
        width: 100%;
        margin-bottom: 30px;
    }

    .maps {
        opacity: .4;
        border: 2px double black;
        width: 330px; 
        height:280px; 
    }
}

@media only screen and (max-width: 400px) {
  .maps {
    opacity: .4;
    border: 2px double black;
    width: 280px; 
    height:240px; 
  }
}
</style>
