<script setup>
import { backendUrl, post } from "../../services/apiReq";
import { useStore } from "vuex";
import { Swiper } from "swiper";
import { Autoplay } from "swiper/modules";
import "swiper/swiper-bundle.css";
import { onMounted, reactive, ref, watch } from "vue";
import { specialDate } from "@/utils/utils";
import Modal from "../components/Products/Modal.vue";
//Swiper.use(Autoplay);

const props = defineProps(["item"]);
const store = useStore();

const cartCounter = ref(1);
const carousel = reactive({});

const carouselInterno = reactive({});
const modal = ref(false);

const sDate = ref();

const calculatePrice = (product) => {
  if (product?.ofertas) {
    const price = product?.precio;
    const discount = product?.ofertas?.descuento;

    const dPrice = price - (discount * price) / 100;
    return dPrice?.toFixed(2);
  } else return product?.precio;
};
const addToCart = async (producto) => {
  let res = await post("/carrito", {
    user_id: store?.getters?.getUser?.id,
    cantidad: cartCounter.value,
    producto_codigo: producto?.codigo,
    observacion: "",
  });
  modal.value = false;
};
async function decrement() {
  if (cartCounter.value <= 1) return;
  cartCounter.value--;
}

async function increment() {
  cartCounter.value++;
}

onMounted(() => {
  getSpecialDate();
  carousel.value = new Swiper(".swiper-container", {
    slidesPerView: "auto",
    autoplay: {
      delay: 1300,
      disableOnInteraction: false,
    },
  });
});

const getSpecialDate = () => {
  sDate.value = specialDate();
};

watch(modal, (value) => {
  if (!value) return;

  setTimeout(() => {
    carouselInterno.value = new Swiper(".swiper-container2", {
      slidesPerView: "auto",
      autoplay: {
        delay: 1300,
        disableOnInteraction: false,
      },
    });
  }, 1000);
});
</script>

<template>
  <Modal
    :item="item"
    v-if="modal"
    :addToCart="addToCart"
    @close="
      () => {
        modal = false;
      }
    "
  ></Modal>
  <a
    class="card_a"
    @click="
      () => {
        modal = true;
        console.log('true');
      }
    "
    target="_blank"
  >
    <div class="card" fade :class="sDate?.name">
      <div class="no-stock-banner" v-if="item?.stock" shadow slideRight>
        <p>SIN STOCK</p>
      </div>
      <div
        class="no-stock-banner"
        v-if="item?.ofertas && !item?.stock"
        shadow
        slideRight
      >
        <p>{{ item?.ofertas?.descuento }}% OFF</p>
      </div>
      <div class="deco-container">
        <!--<div class="colorful-egg"></div>-->
      </div>
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(image, key) in item?.images" :key>
            <img
              :src="`${backendUrl}/files/2/${image}`"
              alt="La Fortaleza Producto"
            />
            <div
              class="no-stock-banner frio"
              v-if="item?.frio"
              shadow
              slideRight
            >
              <p>Necesita cadena de frio</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card__content">
        <h4 class="card-title" nopointer>{{ item?.nombre }}</h4>
        <small id="marca" class="marca">{{ item?.marca }}</small> <br />
        <div
          class="addCart"
          v-if="store?.getters?.getUser?.id && !item?.stock"
          flex-center
          gapped
        >

        <box-icon @click.prevent="addToCart(item)"
          class="icon" title="Añadir al carrito" type='solid' name='cart-download'>
        </box-icon>

          <div class="cart-counter" flex-center gapped>
            <a
              class="add-subtract"
              @click.prevent="decrement"
              flex-center
              radius-border
              >-</a
            >
            <p class="mb-0">{{ cartCounter }}</p>
            <a
              class="add-subtract"
              @click.prevent="increment"
              flex-center
              radius-border
              >+</a
            >
          </div>
        </div>
        <p class="card-price" v-if="store?.getters?.getUser?.id">
          ${{ calculatePrice(item) }}
        </p>
        <div class="orange" v-else>
          <a class="login-to-see" href="/login"
            ><small>Iniciar sesión para ver detalles</small></a
          >
        </div>
      </div>
    </div>
  </a>
</template>

<style scoped lang="scss">
.cart-counter {
  .add-subtract {
    background: var(--easter);
    color: black;
    height: 30px;
    width: 30px;
    transition: var(--duration);

    &:hover {
      border-radius: 8px;
    }
  }
}

.icon {
  background: var(--easter);
  font-size: 20px;
  padding: 6px;
  border-radius: 5px;
  color: black;
  width: 35px !important;
  height: 35px !important;
}

.frio {
  top: unset !important;
  right: unset !important;
  bottom: 0;
}

.swiper-container {
  width: 100%;
  height: 100%;
  background: white;

  .swiper-wrapper {
    width: 100%;
    height: 100%;

    .swiper-slide {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 306px;
        max-height: 306px;
      }
    }
  }
}

.orange {
  color: black;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 1em;
}

.card_a {
  height: 460px !important;
  margin-top: 5em;
  max-width: 30ch;
  display: block;
  width: 100%;
}

.card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  color: #000;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  max-width: 30ch;
  height: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: transform 500ms ease;
  position: relative;
}

.card:hover,
.card:focus-within {
  transform: scale(1.02);
}

.card-price {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 1em;
  color: black !important;
  font-weight: 600;
}

.card__content {
  background: white;
  padding: 1.2rem 0.9rem;
  color: black !important;
}

.card-title {
  text-decoration: none;
}

.card-title:hover {
  text-decoration: underline !important;
}

.card__img img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
}

.no-stock-banner {
  position: absolute;
  right: 0;
  top: 0;
  width: fit-content;
  background: #da3a00;
  padding: 0.2em 1em 0.2em 0.5em;
  z-index: 101;

  p {
    width: 100%;
    text-align: right;
    color: white;
  }
}

.addCart {
  display: flex;
  justify-content: flex-start;
}

.deco-container {
  position: absolute;
  z-index: 100;
  bottom: 240px;
  left: 15%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  width: max-content;
  height: auto;
}

.card-decoration {
  width: 300px;
  height: 280px;
}

.bunny-ears {
  position: absolute;
  top: 220px;
  left: 50%;
  transform: translateX(-50%);
  width: 250px;
  height: 100px;
  background: url("/public/images/prabbit.png") no-repeat center center;
  background-size: contain;
  z-index: 1000;
}

.bunny-img {
  position: absolute;
  z-index: 10000;
  width: 80px;
  height: 100px;
  top: -90px;
  left: 50%;
  transform: translateX(-50%);
}

.colorful-egg {
  position: absolute;
  bottom: -90px;
  left: 20% !important;
  transform: translateX(-50%);
  width: 60px;
  height: 60px;
  background-size: contain;
  animation: eggBounce 2s infinite alternate;
}

@keyframes bounce {
  0% {
    transform: translateX(-50%) rotate(10deg) translateY(0);
  }
  100% {
    transform: translateX(-50%) rotate(10deg) translateY(-15px);
  }
}

@keyframes eggBounce {
  0% {
    transform: translateX(-50%) translateY(0);
  }
  100% {
    transform: translateX(-50%) translateY(-10px);
  }
}

@media only screen and (max-width: 670px) {
  .card_a {
    height: auto !important;
    margin-top: 2em;
    max-width: 300px;
  }

  .card {
    max-width: 300px;
  }

  .deco-container {
    display: none;
  }

  .card-decoration {
    width: 200px;
    height: 200px;
  }

  .card__img img {
    width: 100%;
    height: auto;
  }

  .card-title {
    font-size: 16px;
  }

  .card-body__content {
    padding: 16px 0 30px 0;
  }

  .card-price {
    color: black !important;
    margin: 0.5em;
  }

  .addCart {
    margin: 0.5em;
  }

  .orange {
    margin: 0.5em;
  }
}
</style>
