import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles.scss'
import * as bootstrap from 'bootstrap'
import 'boxicons'
import { createPinia } from 'pinia'
let app = createApp(App);
app.use(store).use(router).use(createPinia()).mount('#app')
