<script setup>
import {Swiper, SwiperSlide} from 'swiper/vue';
import {Pagination} from 'swiper/modules';
import {Autoplay} from "swiper/modules";
import {OverlayCard} from 'digitalpower';
import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import {backendUrl} from "../../services/apiReq";

const width = 200;
const slidesPerView = Math.floor(((90 * window.innerWidth) / 100) / width)

const store = useStore();
const productos = ref([]);

onMounted(async () => {
  productos.value = await store.dispatch('getProducts');
  productos.value = productos.value.data;
})

function go (item) {
  location.href = `/itemdetail?producto=${item?.codigo}`;
}
</script>

<template>
<div class="news">
  <Swiper
      class="adaptive" :slides-per-view="slidesPerView" :space-between="100" :modules="[Pagination, Autoplay]"
      :pagination="{ clickable: true }"
      :autoplay="{delay: 3000,disableOnInteraction: false}"
      :loop="true"
  >
    <SwiperSlide v-for="(item, index) in productos">
      <OverlayCard class="wilton_products" :img="`${backendUrl}/files/2/${item?.images?.[0]}`" :text="item?.nombre" @click="go(item)"></OverlayCard>
    </SwiperSlide>
  </Swiper>
</div>
</template>

<style scoped>
.news {
  width: 90%;
  margin: auto;
}

.wilton_products {
  width: 200px !important;
}

@media screen and (max-width: 500px) {
  .swiper-slide, .swiper-slide-active, .swiper-slide-next {
    display: flex;
    align-content: center !important;
    justify-content: center !important;
  }
}
</style>