<script setup>
import { Image, Carousel, CarouselItem } from 'digitalpower';

const path = '/images/Banners';

const images = [
  {name:'Fortaleza', link: "/" },
  {name:'Fecula', link: "http://lafortaleza.com.ar/itemdetail?producto=10017102051" },
  {name:"Pascuas", link:"https://lafortaleza.com.ar/especial"},
  {name:'Mayonesa', link: "https://lafortaleza.com.ar/newtienda?search=true&nombre=mayonesa" },
  {name:"Moldes", link:"http://lafortaleza.com.ar/newtienda?search=true&nombre=cooper"},
  {name:"Abril", link:"/"},
  {name:"Envios", link:"/"},
  {name:"Choco", link:"/"},
];

function getImage(resolution, image) {
  return `${path}/${image}/${resolution}.svg??`;
}

</script>

<template>
  <div class="hero">
    <Carousel id="hero-carousel" interval="3000" blackArrows>
      <CarouselItem v-for="(image, key) in images" :key :active="key === 0">
        <a :href="image.link">
          <Image :mobile="getImage('mobile', image.name)" :tablet="getImage('pc', image.name)" :pc="getImage('pc', image.name)"
                 :pclarge="getImage('pc-large', image.name)" />
        </a>
      </CarouselItem>
    </Carousel>
  </div>
</template>

<style scoped>
.hero {
  /*height: 400px;*/
}

img {
  width: 100%;
}
</style>
