<script setup>
import {Input} from 'digitalpower';
import {useStore} from "vuex";
import {onMounted, ref} from "vue";
import admins from "../../services/admins";
import {generarToken} from "@/utils/utils";
import {DBService, post} from "../../services/apiReq2";
import QRCode from 'qrcode'
import {show} from "../../services/notification";

const store = useStore();
const token = ref('');
const table = ref('');

onMounted(async () => {
  if(!admins.includes(store.getters?.getUser?.email)){
    location.href = "/";
  }


  localStorage.setItem("user", JSON.stringify( {
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwibmFtZSI6Ik1hcnRpbiIsInN1cm5hbWUiOiJDYXRhbGFubyIsImVtYWlsIjoiY2F0YWxhbm9yZXNpbWFydGluQGdtYWlsLmNvbSIsInBob25lIjoiMTIzIiwiZG5pIjoiMTIzIiwicGFzc3dvcmQiOiIkMmIkMTAkdnNmQ1Z3RHlrNkFncURhZEo3R0k5T1Z5Sy4uQlpXamhCdFJJTGROdG1Wa3hLN2lIS0dncmkiLCJyb2xlIjoic3VwZXItYWRtaW4iLCJzaXRlX25hbWUiOiJwYXBlbGVyYS5kYiIsInBhbmVsX2FkbWluIjoxLCJpYXQiOjE3MzcyMzcxNzl9.mgf0uSAvHfE5FnHVcuPiULkae-mb81LPIjwAiAUWd2g'
  }));
})

async function gen () {
  if(!table.value) {
    show('Debe elegir una tabla')
    return;
  }
  token.value = generarToken();
  let response = await post('/read', {
    table: 'tokens',
    where: [
      {condition: {data: token.value}, strict: true},
      {condition: {name: `formularios_${table.value}`}}
    ]
  }, DBService);
  if (response.data?.rows?.length > 0) {
    await gen()
  }
  else {
    // gen qr
    let response = await post('/insert', {
      table: 'tokens',
      tablename: 'tokens',
      data: {
        data: token.value,
        name: `formularios_${table.value}`,
        status: 'unused',
      }
    }, DBService);
    await QRCode.toCanvas(document.getElementById('canvas'), `https://lafortaleza.com.ar/formulario/${table.value}?token=${token.value}`, (err) => {
      if(err)
      console.log(err)
      else show('QR Generado')
    });
  }
}


</script>

<template>
  <div class="qr" flex flex-column flex-center>
    <div flex flex-wrap gapped>
      <Input v-model="table" type="select" :options="[{name: 'formularios_Sorteo', value: 'sorteo'}, {name: 'formularios_sorteo2', value: 'sorteo2'}]"/>
      <Input @click="gen" type="button" color="white" background="var(--primary)" value="generar"/>
    </div>
    <div class="qr-generado" flex flex-center>
      <canvas class="mt-5" id="canvas"></canvas>
    </div>
  </div>
</template>

<style scoped>
.qr {
  min-height: 500px;
}

.qr-generado {
  height: 400px;
  width: 400px;
}

canvas {
  width: 300px  !important;
  height: 300px !important;
}
</style>